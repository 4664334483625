import {
  Box,
  // TablePagination,
  Pagination,
  Typography,
  // OutlinedInput,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector, RootState } from '../../../store';
// import { getFormGroup } from "../../../store/feature/formGroup";
// import { getFormType } from "../../../store/feature/formType";
import InputForm from '../forms/inputForm';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import SelectForm from '../forms/selectFormm';
import { useLocation, useSearchParams } from 'react-router-dom';

const PaginationControlNonD = ({ params, setParams, total }: any) => {
  // console.log('=====> ', params);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams()
  // const { totalRecForm } = useAppSelector((store: RootState) => store.form);
  const [page, setPage] = useState(1);
  const [pagesize, setPagesize] = useState(10);

  // const { pathname } = useLocation();
  // const idPath = searchParams.get('form-type-id');
  // const formTG = pathname === '/form-group' ? true : false;

  const schema = yup.object({
    goTo: yup.number().required(),
  });
  const {
    control,
    formState: { errors },
  } = useForm<{
    goTo: number;
  }>({
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      goTo: 0,
    },
  });

  // const handleChangePage = (
  //   event: React.MouseEvent<HTMLButtonElement> | null,
  //   newPage: number
  // ) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };
  const handlePerPage = (e: any) => {
    setPagesize(e.value);
  };

  useEffect(() => {
    let tempPage = page;
    const totalPage = calculateTotalPage();
    if (totalPage < page) {
      tempPage = totalPage <= 0 ? 1 : totalPage;
    }
    // if (formTG) {

    let tempParams = {
      ...params,
      page: tempPage,
      pagesize,
    };
    setParams(tempParams);
    const param = {
      // id: idPath,
      params: tempParams,
    };
    // console.log('semua data paginate ===> ', param);
    // dispatch(getBodyTable(param));
    //   );
    // } else {
    //   dispatch(
    //     getBodyTable({
    //       ...params,
    //       page: tempPage,
    //       pagesize,
    //     })
    //   );
    // }
  }, [page, pagesize]);

  const handlePaginate = (event: any, page: any) => {
    setPage(page);
  };

  const calculateTotalPage = () => {
    return Math.ceil(totalRecord() / pagesize);
  };

  const totalRecord = () => {
    return total;
  };

  const handlePageGoto = (e: any) => {
    if (e === '' || e === undefined) {
      setPage(1);
      return;
    }
    setPage(e);
  };
  const optSelect = [
    {
      label: '10/page',
      value: 10,
    },
    {
      label: '20/page',
      value: 20,
    },
    {
      label: '30/page',
      value: 30,
    },
    {
      label: '50/page',
      value: 50,
    },
    {
      label: '100/page',
      value: 100,
    },
  ];

  return (
    <Box
      sx={{
        flexDirection: 'row',
        paddingY: '13px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingRight: '20px',
        gap: 4,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          justifyContent: 'center',
          width: '170px',
          // paddingTop: '10px'
        }}
      >
        <Typography
          variant="paragraph1"
          mt="10px"
        >
          Total {totalRecord()}
        </Typography>
        <SelectForm
          name="perPage"
          defaultValue={1}
          options={optSelect}
          placeholder="10/page"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          onChangeForm={handlePerPage}
        />
      </Box>

      <Pagination
        count={calculateTotalPage()}
        variant="outlined"
        shape="rounded"
        onChange={handlePaginate}
        sx={{mt: 2}}
      />
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          justifyContent: 'center',
          width: '100px',
        }}
      >
        <Typography variant="body1" mt="10px">
          Go to
        </Typography>
        <InputForm
          onChange={handlePageGoto}
          name="goTo"
          required
          maxLength={3}
          control={control}
          disabled={false}
          errors={errors}
          isLoading={false}
          sxFL={{ color: 'red', backgroundColor: 'red' }}
          endAdornment={<Typography variant="body16Regular"></Typography>}
          sxEndAdornment={{ backgroundColor: 'red' }}
        />
      </Box>
    </Box>
  );
};

export default PaginationControlNonD;
