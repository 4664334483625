import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { InputForm, SelectFormm } from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  downloadCertificate,
  getCertificateRequest,
  removeDataSignUpload,
  uploadCertificate,
} from "../../store/feature/structureCaCrls";
import Swal from "sweetalert2";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import signCerReq from "../../utils/validation/signCerReq";
import { initialAccess } from "../../store/feature/user";

const SignCerReqFCAField = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [file, setFile] = useState(null);
  const idParam = useParams();
  const { dataCerfProfile, dataCerfAlgorithm, dataSignUpload, isLoadingEvent } =
    useAppSelector((root: RootState) => root.cerCA);
  const [fileContent, setFileContent] = useState("");
  const [access, setAccess] = useState(initialAccess);
  const { accessCrud } = useAppSelector((store: RootState) => store.user);
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    cerProfile: string;
    cerAlgo: string;
    subjectName: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(signCerReq),
    defaultValues: {
      cerProfile: "",
      cerAlgo: "",
      subjectName: "",
    },
  });

  useEffect(() => {
    const obj = accessCrud.find(
      (item: any) => item.uid === "/sign-certificate-request"
    );
    setAccess(obj != undefined ? obj : initialAccess);
  }, [accessCrud]);

  useEffect(() => {
    dispatch(removeDataSignUpload());
    dispatch(getCertificateRequest({}));
  }, []);

  const handleFileChange = (file: any) => {
    let fileReader = new FileReader();
    fileReader.onloadend = (event: any) => {
      const content = event.target.result;
      setFileContent(content);
    };
    fileReader.readAsText(file[0]);
  };

  const handleUpload = (e: any) => {
    Swal.fire({
      title: "Submit Confirmation",
      text: `Are you sure you want to sign certificate by csr?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const dataUpload = {
          alg_profile_entry_uid: e.cerAlgo,
          cert_profile_entry_uid: e.cerProfile,
          csr_byte: fileContent.toString().replaceAll(`\\n`, `\n`),
          subject_name: e.subjectName,
        };
        dispatch(uploadCertificate(dataUpload));
      }
    });
  };

  const handleCertificateDownload = (type: String) => {
    const downloadData = {
      certificateSerialNumber: dataSignUpload.certificate_serial,
      certificateFormat: type,
    };
    dispatch(downloadCertificate(downloadData));
  };

  return (
    <ListDataSkeleton
      isLoading={false}
      isLoadingEvent={isLoadingEvent}
      titleWidth={"0px"}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(handleUpload)}
        paddingY="20px"
      >
        <Typography variant="h3" fontFamily="Open Sans" paddingBottom={2}>
          Sign Certificate Request
        </Typography>
        <SelectFormm
          name="cerProfile"
          label="Choose the Certificate Profile( Please use specific certificate profile to sign external CSR to avoid error when submitting ) "
          defaultValue={1}
          options={dataCerfProfile}
          placeholder="None"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />
        <SelectFormm
          name="cerAlgo"
          label="Choose the Certificate Algorithm"
          defaultValue={1}
          options={dataCerfAlgorithm}
          placeholder="None"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />
        <InputForm
          name="subjectName"
          label="Input the Subject Name for this Certificate"
          errors={errors}
          control={control}
          required
          placeholder="cn=example,o=company,c=id"
          maxLength={100}
          isLoading={false}
        />
        <InputForm
          name="functionMenuName"
          label="Path to Certificate Signing Request File"
          errors={errors}
          control={control}
          required
          placeholder="Type Function Menu Name Here....."
          maxLength={100}
          isLoading={false}
          type="file"
          onChange={handleFileChange}
        />
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          paddingBottom={2}
          justifyContent="flex-end"
        >
          {access.create ? (
            <Button variant="contained" type="submit">
              Submit
            </Button>
          ) : (
            <Button disabled>No Available Action</Button>
          )}
        </Stack>
        {Object.keys(dataSignUpload).length !== 0 ? (
          <>
            <hr />
            <Typography
              variant="h4"
              fontFamily="Open Sans"
              paddingBottom={2}
              paddingTop={2}
            >
              Certificate Download
            </Typography>
            <Typography variant="body1" paddingBottom={2}>
              Serial Number: {dataSignUpload.certificate_serial}
            </Typography>
            <Stack spacing={2} direction="row" alignItems="center">
              <Button
                variant="contained"
                onClick={() => {
                  handleCertificateDownload("PEM");
                }}
              >
                Download PEM
              </Button>
              {/* <Button variant="contained" onClick={() => { handleCertificateDownload('DER') }}>
              Download DER
            </Button> */}
            </Stack>
          </>
        ) : null}
      </Box>
    </ListDataSkeleton>
  );
};

export default SignCerReqFCAField;
