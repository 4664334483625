import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(officer: string, fungsi: string, time: string) {
  return { officer, fungsi, time };
}

const rows = [
  createData('OfficerEnrolment', 'CheckIssuer', '2022-11-29 20:31:36'),
  createData('OfficerEnrolment', 'CheckIssuer', '2022-11-29 19:31:36'),
  createData('OfficerEnrolment', 'CheckIssuer', '2022-11-29 18:31:36'),
  createData('OfficerEnrolment', 'CheckIssuer', '2022-11-29 17:31:36'),
  createData('OfficerEnrolment', 'CheckIssuer', '2022-11-29 16:31:36'),
  createData('OfficerEnrolment', 'CheckIssuer', '2022-11-29 15:31:36'),
  createData('OfficerEnrolment', 'CheckIssuer', '2022-11-29 14:31:36'),
  createData('OfficerEnrolment', 'CheckIssuer', '2022-11-29 13:31:36'),
  createData('OfficerEnrolment', 'CheckIssuer', '2022-11-29 12:31:36'),
  createData('OfficerEnrolment', 'CheckIssuer', '2022-11-29 11:31:36'),
];

const DashTable = () => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 400 }} aria-label="caption table">
        {/* <caption>A basic table example with a caption</caption> */}
        <TableHead>
          <TableRow>
            <TableCell>Module</TableCell>
            <TableCell align="right">Function</TableCell>
            <TableCell align="right">Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.time}>
              <TableCell component="th" scope="row">
                {row.officer}
              </TableCell>
              <TableCell align="right">{row.fungsi}</TableCell>
              <TableCell align="right">{row.time}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DashTable;
