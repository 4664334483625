import CheckboxForm from "./checkboxForm";
import TextAreaForm from "./textAreaForm";
import InputForm from "./inputForm";
import SelectFormm from "./selectFormm";
import SelectForm from "./selectForm/SelectForm";
import SwitchForm from "./switchForm";
import RadioForm from "./radioForm";
import RadioTable from "./radioInTableForm/RadioTableForm";
import CheckboxFormMultiple from "./checkboxFormMultiple";

export {
    CheckboxForm,
    TextAreaForm,
    InputForm,
    SelectFormm,
    SelectForm,
    SwitchForm,
    RadioForm,
    RadioTable,
    CheckboxFormMultiple
}