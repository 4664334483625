import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance, instanceFormEditor } from "../../service/instance";
import Swal from "sweetalert2";
import { store } from '../index';
import { updateButtonEntry } from "./form";
import { SERVICE_DEFINITION_GEN_CERT_BTN_UID, SERVICE_DEFINITION_GEN_KEY_BTN_UID } from "../../constants/constVal";

export interface IForm {
  isLoadingForm: boolean;
  isLoadingDef: boolean;
  definitionButton: any;
  eventFlagSerDef: boolean;
}

const initialState: IForm = {
  isLoadingForm: false,
  isLoadingDef: false,
  definitionButton: [],
  eventFlagSerDef: false
};

export const getDefitionForButton = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("form/definition-button", async ({id, page_access_uid}, { rejectWithValue }) => {
  try {
    const resp = await instanceFormEditor.get(
      `/service/definition/formtype/${id}`, { 
        headers: {
          'page-access-uid': page_access_uid
          }
       }
    );
    return resp.data.data;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const createServiceDefinitionById = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("service/create-service-definition-id", async (data, { rejectWithValue }) => {
  const { entryId, serDevId, page_access_uid } = data;
  try {
    const resp: any = await instanceFormEditor.post(
      `/service/definition/${serDevId}`,
      { entry_uid: entryId },
      {
        headers: {
          'page-access-uid': page_access_uid
        }
      }
    );
    return { respData: resp.data.data, entryId, serDevId, page_access_uid };
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const serviceDefinition = createSlice({
  name: "serviceDefinition",
  initialState,
  reducers: {
    setEventFlagSerDef: (state) => {
      state.eventFlagSerDef = true;
    },
    removeEventFlagSerDef: (state) => {
      state.eventFlagSerDef = false;
    }
  },
  extraReducers(builder) {
    builder

      // REGION: GET DEFINITION BUTTON
      .addCase(getDefitionForButton.pending, (state) => {
        state.isLoadingForm = true;
      })
      .addCase(getDefitionForButton.fulfilled, (state, action) => {
        state.isLoadingForm = false;
        state.definitionButton = action.payload;
      })
      .addCase(getDefitionForButton.rejected, (state) => {
        state.isLoadingForm = false;
      })

      // REGION: CREATE SERVICE DEFINITION
      .addCase(createServiceDefinitionById.pending, (state) => {
        state.isLoadingDef = true;
        state.eventFlagSerDef = false;
      })
      .addCase(createServiceDefinitionById.fulfilled, (state, action) => {
        state.isLoadingDef = false;
        const { respData, entryId, serDevId, page_access_uid } = action.payload;
        if(respData.data.csr_byte != undefined) {
          const base64String = respData.data.csr_byte
          const fileName = respData.data.csr_name
          const downloadLink = document.createElement('a')
          downloadLink.href = `data:application/octet-stream;base64,${base64String}`
          downloadLink.download = fileName
          downloadLink.click();
        }
        const successMsg = respData.msg != "" ? respData.msg : "Success process data!"
        Swal.fire({
          icon: "success",
          title:"Success",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: '#bfd2e0',
          text: successMsg,
          didOpen: () => Swal.getConfirmButton()?.focus()
        })
        .then((_) => {
          store.dispatch(setEventFlagSerDef())
        });
        if(serDevId == SERVICE_DEFINITION_GEN_CERT_BTN_UID || serDevId == SERVICE_DEFINITION_GEN_KEY_BTN_UID) {
          setTimeout(() => {
          store.dispatch(updateButtonEntry({
            entryId,
            params: {
              generate_button: "true"
            },
            page_access_uid: page_access_uid
          }));
          }, 500);
        }
      })
      .addCase(createServiceDefinitionById.rejected, (state, action) => {
        state.isLoadingDef = false;
        let errMsg = ""
        try {
          errMsg = action.payload.response.data.errors[0].detail
        } catch(e) {
          errMsg = "Can not process data, Please try again"
        }
        // Swal.fire(
        //   'Error!',
        //   errMsg,
        //   'error'
        // )
        Swal.fire({
          icon: "error",
          title: "Error",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: '#bfd2e0',
          text: errMsg,
          didOpen: () => Swal.getConfirmButton()?.focus()
        })
        .then((_) => {
          store.dispatch(removeEventFlagSerDef())
        });
      })

  },
});

export const { setEventFlagSerDef, removeEventFlagSerDef } = serviceDefinition.actions;
export default serviceDefinition.reducer;
