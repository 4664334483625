export default function GlobalStyle(palette: any) {
  return {
    radioButton: {
      '.MuiTypography-root': {
        fontSize: '0.75rem',
        color: palette.neutral.dark,
      },
      '.Mui-checked + span': {
        color: palette.primary.main,
      },
    },
  };
}
