import { Box, Skeleton, Stack, Modal, CircularProgress } from "@mui/material";

const ListDataSkeleton = (props: any) => {
  const {
    isLoading,
    isLoadingEvent,
    children,
    colCount,
    titleWidth,
    contentHeight,
  } = props;

  // REGION: TITLE
  const tempTitleWidth = titleWidth == undefined ? "50%" : titleWidth;

  // REGION: CONTENT
  const temptContentHeight =
    contentHeight === undefined ? 600 : contentHeight;

  // REGION: SEARCH-BAR
  const colLength = colCount == undefined ? 1 : colCount;
  const colItems = Array.from({ length: colLength }, (_, index) => index + 1);
  const colWidth = 100 / colLength;

  // REGION: LOADING EVENT STYLE
  const loadingEventStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  return (
    <Box sx={{ position: "relative" }}>
      {isLoadingEvent && (
        <Modal open={true}>
          <Box sx={loadingEventStyle}>
            <CircularProgress />
          </Box>
        </Modal>
      )}
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            zIndex: 2,
            backgroundColor: "#FFFFFF",
          }}
        >
          {/* REGION: TITLE */}
          <Box height={"24px"} />
          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: "10px" }}
            animation="wave"
            width={tempTitleWidth}
            height={40}
          />

          {/* REGION: SEARCH-BAR */}
          <Box height={"24px"} />
          <Stack direction="row" spacing={2} width="100%">
            {colItems.map((_, index) => {
              return (
                <Box key={index} width={`${colWidth}%`}>
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    sx={{ borderRadius: "10px" }}
                    width="100%"
                    height={40}
                  />
                </Box>
              );
            })}
          </Stack>

          {/* REGION: CONTENT */}
          <Box height={"24px"} />
          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: "10px" }}
            animation="wave"
            width={"100%"}
            height={temptContentHeight}
          />

          {/* REGION: PAGINATION */}
          <Box height={"36px"} />
          <Stack direction="row" width="100%">
            <Skeleton
              variant="rectangular"
              animation="wave"
              sx={{ borderRadius: "10px" }}
              width={200}
              height={40}
            />
            <Box width={"24px"} />
            <Stack direction="row" spacing={2} width="100%">
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ borderRadius: "10px" }}
                width={40}
                height={40}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ borderRadius: "10px" }}
                width={40}
                height={40}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ borderRadius: "10px" }}
                width={40}
                height={40}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ borderRadius: "10px" }}
                width={40}
                height={40}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ borderRadius: "10px" }}
                width={40}
                height={40}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ borderRadius: "10px" }}
                width={70}
                height={40}
              />
            </Stack>
          </Stack>
        </Box>
      )}
      <Box sx={{ position: "absolute", width: "100%", zIndex: 1 }}>
        {" "}
        {children}{" "}
      </Box>
    </Box>
  );
};

export default ListDataSkeleton;
