import React from 'react';
import {
  Box,
  Typography,
  useTheme,
  SxProps,
  Paper,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Skeleton,
} from '@mui/material';
import RadioForm from '../radioForm';
import { IDefaultDropdown } from '../../../../interfaces/Options';

interface IRadioTableProps {
  forms: Array<any>;
  isLoading?: boolean;
  containerSx?: SxProps;
  control: any;
  errors: any;
  disabled?: boolean;
  options: Array<any>;
  defaultValue: string | number;
  label: string;
  name: string;
}

export default function RadioTable(props: IRadioTableProps) {
  const {
    forms,
    isLoading = false,
    containerSx,
    control,
    errors,
    disabled = false,
    options,
    defaultValue,
    label,
    name,
  } = props;

  const { palette } = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        ...containerSx,
      }}
    >
      <TableContainer
        sx={{
          border: '1px solid #E5EAEE',
          borderTopRightRadius: '4px',
          borderTopLeftRadius: '4px',
          borderBottomRightRadius: '4px',
          borderBottomLeftRadius: '4px',
          boxSizing: 'border-box',
        }}
        component={Paper}
      >
        <Table
          sx={{
            minWidth: 650,
            borderCollapse: 'separate',
          }}
          size="medium"
        >
          <TableHead>
            <TableRow>
              <TableCell
                key={name}
                sx={{
                  color: palette.gray.dark,
                  backgroundColor: palette.white.dark,
                  borderBottom: '1px solid #E5EAEE',
                  borderTopRightRadius: '4px',
                }}
                align="left"
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                  }}
                >
                  {label}
                </div>
              </TableCell>
              {options.map((d, i) => (
                <TableCell
                  key={d.value}
                  sx={{
                    color: palette.gray.dark,
                    backgroundColor: palette.white.dark,
                    borderBottom: '1px solid #E5EAEE',
                    borderTopRightRadius:
                      i === options.length - 1 ? '4px' : '0px',
                  }}
                  align="center"
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      justifyContent: 'center',
                    }}
                  >
                    {d.label}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading &&
              Array.from(Array(10).keys()).map((x) => (
                <TableRow
                  key={x}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '&:hover': { backgroundColor: '#F3F6F9' },
                  }}
                >
                  <TableCell
                    sx={{
                      height: '20px',
                      width: '20px',
                      marginRight: '16px',
                    }}
                    component="th"
                    scope="row"
                  >
                    <Skeleton />
                  </TableCell>
                  {options.map((d) => (
                    <TableCell key={d.value} component="th" scope="row">
                      <Skeleton />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            {!isLoading &&
              forms?.map((form: { [index: string]: any }, idx: number) => (
                <TableRow
                  key={form.name}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '&:hover': { backgroundColor: '#F3F6F9' },
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      fontSize: '1rem',
                      position: 'relative',
                      overflow: 'hidden',
                    }}
                  >
                    {form.label}
                  </TableCell>
                  {options.map((option: IDefaultDropdown) => (
                    <TableCell
                      key={option.value}
                      component="th"
                      scope="row"
                      sx={{
                        fontSize: '1rem',
                        position: 'relative',
                        overflow: 'hidden',
                        px: '1rem',
                        py: '1.5rem',
                      }}
                    >
                      <RadioForm
                        defaultValue={defaultValue}
                        label=""
                        name={`${name}[${idx}][value]`}
                        options={[option]}
                        control={control}
                        errors={errors}
                        hideRadioLabel
                        disabled={disabled}
                        sxFC={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          mb: '0rem',
                        }}
                        formSx={{ margin: '0rem' }}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            {!isLoading && forms.length === 0 && (
              <TableRow>
                <TableCell colSpan={options.length + 1}>
                  <Typography
                    variant="body14Regular"
                    sx={{
                      color: palette.gray.dark,
                      display: 'flex',
                      flexDirection: 'row',
                      flex: 1,
                      justifyContent: 'center',
                    }}
                  >
                    No data available in table
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
