import { Box, Grid, Skeleton, Modal, CircularProgress } from '@mui/material';

const StructureCaCrlsInformationSkeleton = (props: any) => {
  const { isLoading, isLoadingEvent, children } = props;

  // REGION: LOADING EVENT STYLE
  const loadingEventStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  };

  return (
    <Box sx={{ position: 'relative' }}>
        {
        isLoadingEvent && <Modal open={true}>
            <Box sx={loadingEventStyle}>
                <CircularProgress />
            </Box>
        </Modal>
        }
        {
        isLoading &&
        <Box sx={{ position: 'absolute', width:'100%', zIndex: 2, backgroundColor: '#FFFFFF' }}>
            <Grid container>
                <Grid item xs={12} justifyContent="center" display={"flex"} >
                    <Skeleton
                        variant="rectangular"
                        sx={{ borderRadius: '10px',  marginTop: '24px' }} animation="wave" width={'90%'} height={55}
                    />
                </Grid>
            </Grid>
            <Skeleton variant="rectangular" sx={{ borderRadius: '10px', marginTop: '18px' }} animation="wave" width={'250px'} height={40}/>
            <Grid container>
                <Grid item xs={12} justifyContent="center" display={"flex"} >
                    <Skeleton
                        variant="rectangular"
                        sx={{ borderRadius: '10px',  marginTop: '43px' }} animation="wave" width={'35%'} height={40}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} justifyContent="center" display={"flex"} >
                    <Skeleton
                        variant="rectangular"
                        sx={{ borderRadius: '10px',  marginTop: '58px' }} animation="wave" width={'90%'} height={700}
                    />
                </Grid>
            </Grid>
        </Box>
        }
        <Box sx={{ position: 'absolute', width:'100%', zIndex: 1,  }}> {children} </Box>
    </Box>
    );
};

export default StructureCaCrlsInformationSkeleton;
