import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import certificateProfile from "../../utils/datajson/certificateProfile.json";

export const getCertificateProfile = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("certificateProfile", async (data, { rejectWithValue }) => {
  const { last } = data;
  try {
    const resp = await certificateProfile;
    // initialState.formParams = resp.form_params
    // initialState.formType = resp.form_type
    return resp;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

interface ICertificateProfile {
  formType: string;
  formParams: Array<object>;
  keyAlgo: string;
  keyAlgoOpt: Array<Object>;
  keyEcdsa: string;
  keyEcdsaOpt: Array<Object>;
  keyEcdsaOptEcdsa: Array<Object>;
  keyBitLen: string;
  keyBitLenOpt: Array<Object>;
  keyBitLenOptDsa: Array<Object>;
  keyBitLenOptRsa: Array<Object>;
  keyBitLenOptEcdsa: Array<Object>;
  keyPage: string;
}

const initialState: ICertificateProfile = {
  formParams: [],
  formType: "",
  keyAlgo: "",
  keyAlgoOpt: [],
  keyEcdsa: "",
  keyEcdsaOpt: [],
  keyEcdsaOptEcdsa: [],
  keyBitLen: "",
  keyBitLenOpt: [],
  keyBitLenOptDsa: [],
  keyBitLenOptRsa: [],
  keyBitLenOptEcdsa: [],
  keyPage: "",
};

export const certificateProfileSlice = createSlice({
  name: "certificateProfile",
  initialState,
  reducers: {
    setFormParams: (state) => {
      state.formParams = certificateProfile.form_params;
      state.formType = certificateProfile.form_type.name;
    },
    setPageKey: (state, action) => {
      console.log("from setpagekey store ===> ", action.payload);
      state.keyPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCertificateProfile.fulfilled, (state, action) => {
      // state.formParams = action.form_params;
      // state.formType = action.form_type
      state.formParams = action.payload.form_params;
      state.formType = action.payload.form_type.name;
      state.keyAlgo = action.payload.form_params[0].param_name;
      state.keyAlgoOpt = action.payload.form_params[0].option_value.map(
        (item: any) => ({
          value: item.option_id,
          label: item.value,
          parentValue: item.parent_val,
        })
      );
      state.keyEcdsa = action.payload.form_params[1].param_name;
      state.keyEcdsaOpt = action.payload.form_params[1].option_value.map(
        (item: any) => ({
          value: item.option_id,
          label: item.value,
          parentValue: item.parent_val,
        })
      );
      state.keyEcdsaOptEcdsa = action.payload.form_params[1].option_value.map(
        (item: any) => ({ value: item.option_id, label: item.value })
      );
      state.keyBitLen = action.payload.form_params[2].param_name;
      state.keyBitLenOpt = action.payload.form_params[2].option_value.map(
        (item: any) => ({
          value: item.option_id,
          label: item.value,
          parentValue: item.parent_val,
        })
      );
      state.keyBitLenOptDsa = action.payload.form_params[2].option_value
        .filter((item: any) => item.parent_val === "DSA")
        .map((item: any) => ({ value: item.option_id, label: item.value }));
      state.keyBitLenOptEcdsa = action.payload.form_params[2].option_value
        .filter((item: any) => item.parent_val === "RSA")
        .map((item: any) => ({ value: item.option_id, label: item.value }));
      state.keyBitLenOptRsa = action.payload.form_params[2].option_value
        .filter((item: any) => item.parent_val === "ECDSA")
        .map((item: any) => ({ value: item.option_id, label: item.value }));
    });
  },
});

export const { setFormParams, setPageKey } = certificateProfileSlice.actions;

export default certificateProfileSlice.reducer;
