import { Box, Skeleton, Modal, CircularProgress } from '@mui/material';

const StuctureCaCrlsCertificateSkeleton = (props: any) => {
  const { isLoading, isLoadingEvent, children } = props;

  // REGION: LOADING EVENT STYLE
  const loadingEventStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  };

  return (
    <Box sx={{ position: 'relative' }}>
        {
        isLoadingEvent && <Modal open={true}>
            <Box sx={loadingEventStyle}>
                <CircularProgress />
            </Box>
        </Modal>
        }
        {
        isLoading && <Box sx={{ position: 'absolute', width:'100%', zIndex: 2, backgroundColor: '#FFFFFF' }}>
            <Box height={'24px'}/>
            <Skeleton variant="rectangular" sx={{ borderRadius: '10px' }} animation="wave" width="350px" height={40}/>
            <Box height={'18px'}/>
            <Skeleton variant="rectangular" sx={{ borderRadius: '10px' }} animation="wave" width={'90%'} height={550}/>
        </Box>
        }
        <Box sx={{ position: 'absolute', width:'100%', zIndex: 1,  }}> {children} </Box>
    </Box>
    );
};

export default StuctureCaCrlsCertificateSkeleton;
