import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import { RootState, useAppDispatch, useAppSelector } from '../../../store';
import { getFormGroup } from '../../../store/feature/formGroup';
import { useNavigate } from 'react-router-dom';
import PaginationControl from '../pagination/Pagination';
import { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import { initialAccess } from '../../../store/feature/user';
import './FormGroupDataGrid.css';

const SearchCertificateTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { totalKeys } = useAppSelector((store: RootState) => store.cerCA);
  const [ access, setAccess ] = useState(initialAccess)
  const { accessCrud } = useAppSelector((store: RootState) => store.user);

  useEffect(() => {
    const obj = accessCrud.find((item: any) => item.uid === "/search-certificate")
    setAccess(obj != undefined ? obj : initialAccess)
  }, [accessCrud]);

  // useEffect(() => {
  //   dispatch(getFormGroup({ pagesize: 10 }));
  // }, []);

  const customCellRender = (params: GridCellParams) => {
    const onViewRevocation = (e: any) => {
      e.stopPropagation();
      navigate(`/search-certificate/sign-csr/${params.row.certificateSerialNumber}`);
    };
    return (
      <Stack spacing={2} direction="row">
        {
          access.read
          ? <Button variant="contained" onClick={onViewRevocation}>
            Certificate Information
          </Button>
          : <Typography variant='body2'> No Action Available </Typography>
        }
      </Stack>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'certificateSerialNumber',
      headerName: 'Certificate Serial Number',
      flex: 1,
      minWidth: 300,
    },
    {
      field: 'subjectName',
      headerName: 'Subject Name',
      flex: 1,
      minWidth: 300,
    },
    {
      field: 'creationDate',
      headerName: 'Creation Date',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'issuerSubjectName',
      headerName: 'Issuer Subject Name',
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <>
            {params.row.status !== null ? (
              params.row.status.toLowerCase() === 'active' ? (
                <Chip label="ACTIVE" color="success" variant="outlined" />
              ) : (
                <Chip label="REVOKED" color="warning" variant="outlined" />
              )
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  const rows = data.map((row: any) => ({
    id: row.certificate_id,
    certificateSerialNumber: row.certificate_serial_number,
    subjectName: row.certificate_subject_name,
    creationDate: row.certificate_creation_date,
    issuerSubjectName: row.certificate_issuer_subject_name,
    status: row.certificate_status
  }));

  return (
    <>
      <Box sx={{ height: '650px' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          // autoHeight
        />
      </Box>
      <PaginationControl params={params} setParams={setParams}  total={totalKeys} />
    </>
  );
};

export default SearchCertificateTable;
