import { Box } from "@mui/material";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import "./FormGroupDataGrid.css";
import { useParams } from "react-router-dom";
import { getCAInformation, removeDataCAInformation } from "../../../store/feature/structureCaCrls";

const StrucCaCrlsTableCertificate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const idParam = useParams();
  const { dataCAInformation } = useAppSelector((state: RootState) => state.cerCA);

  useEffect(() => {
      dispatch(getCAInformation(idParam.id));
  }, []);

  const columns: GridColDef[] = [
    {
      field: "label",
      headerName: "Label",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "value",
      headerName: "Value",
      flex: 3,
      minWidth: 500,
      cellClassName: 'super-app-theme--header'
    }
  ];

  return (
    <>
      <Box sx={{ 
        height: "600px",
        width: '90%', 
        '& .super-app-theme--header': {
          borderLeft: '1px solid #ddd;',
        },
       }}>
        <DataGrid
          rows={dataCAInformation}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          autoHeight
          // getRowHeight={() => "auto"}
          headerHeight={0}
        />
      </Box>
    </>
  );
};

export default StrucCaCrlsTableCertificate;
