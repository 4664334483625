import React, { useEffect } from 'react';
import { Doughnut, Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';

const BarChart = () => {
  // useEffect(() => {
  //   console.log(chartData, 'baru dari chardata');
  // });
  return (
    <Line
      datasetIdKey="id"
      data={{
        labels: ['Jun', 'Jul', 'Aug'],
        datasets: [
          {
            label: '',
            data: [5, 6, 7],
          },
          {
            label: '',
            data: [3, 2, 1],
          },
        ],
      }}
      options={{
        maintainAspectRatio: true,
      }}
    />
  );
};

export default BarChart;
