import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../../store";
import {
  deleteFormEntry,
  getBodyTable,
  getHeaderTable,
  removeEventFlag,
} from "../../../store/feature/form";
import { DataGrid } from "@mui/x-data-grid";
import { InputForm } from "../../molecules/forms";
import { useForm } from "react-hook-form";
import { getCerProf, getFormEntryList } from "../../../store/feature/cerProf";
import {
  createServiceDefinitionById,
  getDefitionForButton,
  removeEventFlagSerDef,
} from "../../../store/feature/serviceDefinition";
import { removeEntryId } from "../../../store/feature/form";
import { removeUpdateMsg } from "../../../store/feature/form";
import Swal from "sweetalert2";
import AddIcon from "@mui/icons-material/Add";
import PaginationDynamic from "../../molecules/pagination/paginationDynamic";
import DynamicListSkeleton from "../../template/skeleton/DynamicListSkeleton";
import { initialAccess } from "../../../store/feature/user";
import {
  SERVICE_DEFINITION_GEN_CERT_BTN_UID,
  SERVICE_DEFINITION_GEN_KEY_BTN_UID,
  SERVICE_DEFINITION_IMPORT_UID,
} from "../../../constants/constVal";

const DynamicTable = () => {

  //#region INIT

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [params, setParams] = useState<any>({});
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const formTypeId = searchParams.get("form-type-uid");
  const result: any = [];
  const {
    formData,
    rowsData,
    formTypeName,
    isLoadingForm,
    isLoadingEvent,
    eventFlag,
  } = useAppSelector((state: RootState) => state.form);
  const {
    definitionButton,
    isLoadingDef,
    eventFlagSerDef
  } = useAppSelector(
    (state: RootState) => state.serDef
  );
  const [access, setAccess] = useState(initialAccess);
  const {
    accessCrud
  } = useAppSelector((store: RootState) => store.user);
  const {
    control,
    // handleSubmit,
    watch,
    formState: { errors },
    // setValue,
    // reset,
    // getValues,
  } = useForm<{}>({
    reValidateMode: "onChange",
    // resolver: yupResolver(params),
    // shouldUnregister: false,
    defaultValues: {},
  });

  const customCellRender = (params: any) => {
    const onEdit = (e: any) => {
      e.stopPropagation();
      dispatch(removeUpdateMsg());
      dispatch(getFormEntryList({ id: params.id, page_access_uid: access.page_access_uid }));
      const pathnameList = pathname.split("?");
      navigate(
        `${pathnameList[0]}/edit?form-type-uid=${formTypeId}&params-uid=${params.id}`
      );
    };

    const onDefinition = (e: any) => {
      Swal.fire({
        title: `Confirmation`,
        text: `Are you sure you want to “${e.service_definition_name}“?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          if (e.service_definition_uid == SERVICE_DEFINITION_IMPORT_UID) {
            const label = Object.values(params.row)[0];
            navigate(
              `/import-certificate-ca?entry-uid=${params.id}&label=${label}&page-access-uid=${access.page_access_uid}`
            );
          } else {
            dispatch(
              createServiceDefinitionById({
                entryId: params.id,
                serDevId: e.service_definition_uid,
                page_access_uid: access.page_access_uid
              })
            );
          }
        }
      });
    };

    const onDelete = (e: any) => {
      e.stopPropagation();
      Swal.fire({
        title: "Delete Confirmation",
        text: "Are you sure? You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteFormEntry({ id: params.id, page_access_uid: access.page_access_uid }));
        }
      });
    };

    const filteredButton = () => {
      let buttons: any = [];
      if(params.row.impBtn == "true" && params.row.genBtn == "true") {
        buttons = [];
      }
      else if(params.row.impBtn == "true" && (params.row.genBtn == null || params.row.genBtn == "false")) {
        buttons = [];
      }
      else if((params.row.impBtn == null || params.row.impBtn == "false") && params.row.genBtn == "true") {
        buttons = definitionButton?.filter((i: any) => 
          i.service_definition_uid != SERVICE_DEFINITION_GEN_CERT_BTN_UID &&
          i.service_definition_uid != SERVICE_DEFINITION_GEN_KEY_BTN_UID
        )
      }
      else {
        buttons = definitionButton?.filter((i: any) => true)
      };
      return buttons;
    }

    let justify = "center",
      xs = 3;
    if (definitionButton?.length) {
      justify = "normal";
      xs = 4;
    }
    return (
      <>
        {access.update ||
        access.delete ||
        (access.create && definitionButton) ? (
          <Grid
            container
            spacing={2}
            mt="20px"
            mb="20px"
            justifyContent={justify}
          >
            {access.update && (
              <Grid item xs={xs}>
                <Button
                  variant="contained"
                  onClick={onEdit}
                  sx={{ width: "100%", height: "60px" }}
                >
                  Update
                </Button>
              </Grid>
            )}
            {access.delete && (
              <Grid item xs={xs}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={onDelete}
                  sx={{ width: "100%", height: "60px" }}
                >
                  Delete
                </Button>
              </Grid>
            )}
            {access.create &&
              filteredButton()?.map((item: any) => {
                return (
                  <Grid item xs={4}>
                    <Button
                      key={item.service_definition_Id}
                      variant="contained"
                      color="gray"
                      sx={{ width: "100%", height: "60px" }}
                      onClick={() => onDefinition(item)}
                    >
                      {item.service_definition_name}
                    </Button>
                  </Grid>
                );
              })}
          </Grid>
        ) : (
          <Typography variant="body2"> No Action Available </Typography>
        )}
      </>
    );
  };

  const gridColDef = formData.map((row: any) => ({
    field: row.form_param_id.toString(),
    headerName: row.form_param_title,
    flex: 1,
  }));

  gridColDef.push({
    field: "action",
    headerName: "Action",
    headerAlign: "center",
    align: "center",
    minWidth: 260,
    flex: 2,
    sortable: false,
    renderCell: customCellRender,
  });

  rowsData?.forEach((e: any) => {
    const dataTemp: any = {};
    dataTemp.id = e.form_entry_uid;
    dataTemp.genBtn = e.form_entry_gen_button;
    dataTemp.impBtn = e.form_entry_import_button;
    e.form_values?.forEach((i: any) => {
      dataTemp[i.form_parameter_detail.form_parameter_id] = i.form_value.toString();
    });
    result.push(dataTemp);
  });

  //#endregion

  //#region DISPATCH

  useEffect(() => {
    const obj = accessCrud.find((item: any) => item.uid === formTypeId);
    setAccess(obj != undefined ? obj : initialAccess);
  }, [accessCrud, formTypeId]);

  useEffect(() => {
    if(
      access.page_access_uid != "" && 
      access.page_access_uid != undefined && 
      access.page_access_uid != null
    ) {
      dispatchData();
    }
  }, [access]);

  useEffect(() => {
    if (eventFlag || eventFlagSerDef) {
      dispatchData();
      dispatch(removeEventFlag());
      dispatch(removeEventFlagSerDef());
    }
  }, [eventFlag, eventFlagSerDef]);

  const dispatchData = () => {
    dispatch(
      getHeaderTable({
        id: formTypeId,
        page_access_uid: access.page_access_uid
      })
    );
    if(
      access.page_access_uid != "" && 
      access.page_access_uid != undefined && 
      access.page_access_uid != null
    ) {
      dispatch(
        getBodyTable({
          id: formTypeId,
          params: { pagesize: 10 },
          page_access_uid: access.page_access_uid
        })
      );
    }
    dispatch(
      getDefitionForButton({
        id: formTypeId,
        page_access_uid: access.page_access_uid
      })
    );
  }

  useEffect(() => {
    const filters: any = watch();
    let temp: any = [];
    for (const key in filters) {
      if (Object.prototype.hasOwnProperty.call(filters, key)) {
        const element = filters[key];
        if (element?.length !== 0 && element?.length !== undefined) {
          temp.push(`${key}+${element.trim()}`);
        }
      }
    }
    let param = { filter: temp.join(";;") };
    setParams(param);
    if(
      access.page_access_uid != "" && 
      access.page_access_uid != undefined && 
      access.page_access_uid != null
    ) {
      dispatch(getBodyTable({ id: formTypeId, params: param, page_access_uid: access.page_access_uid }));
    }
  }, [JSON.stringify(watch())]);

  //#endregion

  //#region HANDLE

  const handleAdd = () => {
    dispatch(removeEntryId());
    dispatch(removeUpdateMsg());
    dispatch(getCerProf({ id: formTypeId, page_access_uid: access.page_access_uid }));
    const pathnameList = pathname.split("?");
    navigate(`${pathnameList[0]}/create?form-type-uid=${formTypeId}`);
  };

  const handleInputChange = (e: any) => {};

  //#endregion

  //#region RETURN TSX

  return (
    <DynamicListSkeleton
      isLoading={isLoadingForm}
      isLoadingEvent={isLoadingEvent || isLoadingDef}
      colCount={4}
      titleWidth={"300px"}
      isAddButton={true}
    >
      <Typography variant="h3" sx={{ paddingY: "20px" }}>
        {formTypeName}
      </Typography>
      {access.create && (
        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
        <Button variant="contained" onClick={handleAdd}>
          <AddIcon /> Create
        </Button>
        </Box>
      )}
      {access.read && (
        <>
          <Box
            sx={{
              flexgrow: 1,
              mt: "-1rem",
              mb: "0.7rem",
            }}
          >
            <Grid container spacing={2}>
              {formData.map((e: any, i: number) => {
                return (
                  <Grid item xs={formData.length === 3 ? 4 : 3}>
                    <InputForm
                      name={e.form_param_uid}
                      errors={errors}
                      control={control}
                      required
                      placeholder={e.form_param_title}
                      maxLength={100}
                      key={e.form_param_id}
                      isLoading={false}
                      onChange={handleInputChange}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>

          <div style={{ height: '650px' }}>
            <DataGrid
              rows={result}
              columns={gridColDef}
              hideFooter={true}
              disableColumnMenu
              disableColumnSelector
              disableSelectionOnClick
              disableDensitySelector
              getRowHeight={() => "auto"}
            />
            <PaginationDynamic params={params} setParams={setParams} page_access_uid={access.page_access_uid} />
          </div>
        </>
      )}
    </DynamicListSkeleton>
  );

  //#endregion

};

export default DynamicTable;
