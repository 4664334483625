import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useNavigate } from "react-router-dom";

// icons
// import AddIcon from "@mui/icons-material/Add";
// import Toast from "../../components/atoms/toast";
import StructureCaCrlsSearch from "../../components/molecules/structureCaCrls/StructureCaCrlsSearch";
import StructureCaCrlsTable from "../../components/molecules/structureCaCrls/StructureCaCrlsTable";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";

const StructureCaCrls = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { isLoadingCertCAandCRL, isLoadingEvent } = useAppSelector((state: RootState) => state.cerCA);


  return (
    <ListDataSkeleton isLoading={isLoadingCertCAandCRL} isLoadingEvent={isLoadingEvent} colCount={1} titleWidth={'300px'} contentHeight={650}>
      <Typography variant="h3" fontFamily="Open Sans" sx={{ marginY: "20px" }}>
        CA Structure & CRLs
      </Typography>
      <StructureCaCrlsSearch
        params={params}
        setParams={setParams}
      />
      <StructureCaCrlsTable 
        theParams={params}
        setParams={setParams}
        defaultValue={defaultValue}
        setDefaultValue={setDefaultValue} /> 
    </ListDataSkeleton>
  );
};

export default StructureCaCrls;
