import { Avatar, Box } from '@mui/material'
import React from 'react'

const Profile = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
        <Avatar
                alt="Remy Sharp"
                src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              />
    </Box>
  )
}

export default Profile