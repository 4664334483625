import { FormControl, FormHelperText, useTheme, Skeleton } from '@mui/material';
import React, { useMemo } from 'react';
// import Skeleton from 'react-loading-skeleton';
import { Control, Controller } from 'react-hook-form';
import Select from 'react-select';
import FormLabel from '../../../atoms/formLabel/index';
import { StyledBox } from './element';

interface Iprops {
  sxFC?: any;
  sxFL?: any;
  name: string;
  label?: string;
  errors?: any;
  control?: Control<any, any>;
  options: any[];
  defaultValue: string | number | Array<any>;
  disabled?: boolean;
  onChangeForm?: (value: any) => void;
  required?: boolean;
  placeholder?: string;
  isMulti?: boolean;
  menuPlacement?: any;
  closeMenuOnSelect?: boolean;
  specificName?: string;
  isLoading?: boolean;
  isClear?: boolean;
}

function SelectForm(props: Iprops) {
  const {
    sxFC,
    sxFL,
    name,
    label,
    control,
    defaultValue,
    errors,
    options,
    disabled,
    onChangeForm,
    required = false,
    placeholder = '',
    isMulti,
    menuPlacement = 'auto',
    closeMenuOnSelect = !isMulti,
    specificName = '',
    isLoading = false,
    isClear = false,
  } = props;
  const { palette } = useTheme();

  const customBorder = (state: any, provided: any) => {
    if (!!errors[name] || !!errors[specificName]) {
      return `1px solid ${palette.error.main}`;
    }
    if (state.isDisabled) {
      return 'none';
    }
    return provided.border;
  };

  const customeMultiValueStyle = (styles: any, state: any) => {
    if (isMulti && state.isDisabled) {
      return {
        ...styles,
        backgroundColor: palette.gray.main,
      };
    }
    return {
      ...styles,
      backgroundColor: palette.primary.light,
    };
  };

  const customeMultiValueLabel = (styles: any, state: any) => {
    if (isMulti && state.isDisabled) {
      return {
        ...styles,
        WebkitTextFillColor: palette.neutral.main,
      };
    }
    return {
      ...styles,
      WebkitTextFillColor: palette.primary.main,
    };
  };

  const customeMultiValueRemove = (styles: any, state: any) => {
    if (isMulti && state.isDisabled) {
      return { display: 'none' };
    }
    return {
      ...styles,
      color: palette.primary.main,
      ':hover': {
        cursor: 'pointer',
      },
    };
  };

  const customStyles = useMemo<{ [key: string]: Function }>(
    () => ({
      control: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isDisabled
          ? palette.gray.light
          : 'hsl(0, 0%, 100%)',
        WebkitTextFillColor: palette.neutral.main,
        color: palette.neutral.main,
        border: customBorder(state, provided),
      }),
      multiValue: customeMultiValueStyle,
      multiValueLabel: customeMultiValueLabel,
      multiValueRemove: customeMultiValueRemove,
      valueContainer: (style: any) => ({
        ...style,
        minHeight: '38px',
      }),
      dropdownIndicator: (style: any, state: any) => {
        if (state.isDisabled) {
          return {
            ...style,
            color: palette.neutral.dark,
          };
        }
        return {
          ...style,
        };
      },
      indicatorSeparator: (style: any, state: any) => ({ display: 'none' }),
    }),
    []
  );
  return (
    <FormControl
      sx={{
        flex: 1,
        display: 'flex', mb: '0.5rem', mt: '1rem',
        ...sxFC,
        '&:hover': {
          cursor: disabled ? 'no-drop' : 'default',
        },
      }}
      variant="standard"
    >
      {label?.length !== 0 && (
        <FormLabel
          htmlFor={name}
          sx={{ color: palette.neutral.dark, marginBottom: '0.5rem', ...sxFL }}
        >
          {label}{' '}
          {!required && !disabled && (
            <span
              style={{
                marginLeft: '4px',
                color: palette.neutral.light,
                fontWeight: 400,
                fontSize: '1rem',
                lineHeight: '1.5rem',
                fontStyle: 'italic',
              }}
            >(optional)</span>
          )}
          {required && label !== undefined && !disabled && (
             <span style={{color: '#ef4545'}}> *</span>
          )}
        </FormLabel>
      )}
      <Controller
        // defaultValue={defaultValue}
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <>
            {!isLoading && (
              <Select
                inputId={name}
                styles={customStyles}
                isDisabled={disabled}
                isClearable={isClear}
                value={
                  isMulti
                    ? value
                    : options.find((e: any) => e.value === value) || null
                }
                options={options}
                isMulti={isMulti}
                onChange={(e: any) => {
                  if (!isMulti) {
                    const selectedOption = e ? options.find((option: any) => option.value === e.value) : null;
                    onChange(selectedOption ? selectedOption.value : null);
                  }
                  if (isMulti) {
                    const tempValue = e.length ? e : null;
                    onChange(tempValue);
                  }

                  onChangeForm?.(e);
                }}
                placeholder={
                  <div className="select-placeholder-text">
                    {placeholder || `Select ${label}`}
                  </div>
                }
                menuPlacement={menuPlacement}
                closeMenuOnSelect={closeMenuOnSelect}
              />
            )}
            {isLoading && (
              <StyledBox>
                <Skeleton
                  style={{
                    height: '40px',
                    borderRadius: '4px',
                  }}
                />
              </StyledBox>
            )}
          </>
        )}
      />
      <FormHelperText sx={{ fontSize: '0.75rem', color: palette.error.main }}>
        {errors[name]?.message && errors[name]?.message}
      </FormHelperText>
    </FormControl>
  );
}

export default SelectForm;
