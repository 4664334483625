import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken, setAdminRoles, setToken } from "../../utils/storage";
import { encrypt } from "../../utils/hash/password";
// import { decodeToken } from "react-jwt";
import { instanceAdmin } from "../../service/instance";
import { DYNAMIC_MENU_UID } from "../../constants/constVal";
import { clearStorage } from "../../utils/storage";
import Swal from "sweetalert2";

export interface ILogin {
  email: string;
  password: string;
  showPass: boolean;
}

export interface IAccess {
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
  uid: string;
  page_access_uid: string;
  errorRoleType: string;
}

export const initialAccess: IAccess = {
  create: false,
  read: false,
  update: false,
  delete: false,
  uid: "",
  page_access_uid: "",
  errorRoleType: ""
};

export interface ISubmitLogin {
  rejectMessage: string;
  isLoading: boolean;
  name: string;
  roleTitle: string;
  accesToken: string;
  adminRole: any;
  accessSidebar: any;
  accessCrud: IAccess[];
  appVersion: string;
  errorRoleType: string;
}

const initialState: ISubmitLogin = {
  rejectMessage: "",
  isLoading: false,
  name: "",
  roleTitle: "",
  accesToken: getToken(),
  adminRole: {},
  accessSidebar: [],
  accessCrud: [],
  appVersion: "",
  errorRoleType: "",
};

export const submitLogin = createAsyncThunk<any, any, { rejectValue: any }>(
  "user/submitLogin",
  async (dataToPost, { rejectWithValue, dispatch }) => {
    const { Email, Password } = dataToPost;
    try {
      let pasEnc = encrypt(Password);
      const resp = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_API_ADMIN}/user/login`,
        data: {
          Email,
          password: pasEnc,
        },
      });
      const token = resp.data.data.access;
      setToken(token);
      dispatch(getRoleType({}));
      return resp.data;
    } catch (error: any) {
      const errorMessage =  error?.response.data.msg.toLowerCase().includes('email')
      if(errorMessage){
        Swal.fire('Error Login', 'Invalid login email/password', 'error')
      }else {
        Swal.fire('Error Login', error?.response.data.msg, 'error')
      }
      throw rejectWithValue(error);
    }
  }
);

export const getRoleType = createAsyncThunk<any, any, { rejectValue: any }>(
  "auth/get-role",
  async (data, { rejectWithValue, dispatch,  }) => {
    try {
      const resp: any = await instanceAdmin.get(`/user/auth-data/ca`
      );
      
      // GET ACCESS ROLE DATA
      const accessRole = resp.data.data;

      // GET ACCESS SIDEBAR
      const accessSidebar = accessRole.admin_page_access.map((form:any) => {
         if(form.pge_master_access_url != null && form.pge_master_access_url != "") {
          return form.pge_master_access_url.replace("/","")
        } else {
          return form.pge_frm_type_uid
        }
      })
      accessSidebar.push(DYNAMIC_MENU_UID)
      accessSidebar.push("dashboard")

      // GET ACCESS CRUD
      const accessCrud: IAccess[] = accessRole.admin_page_access.map((form:any) => {
        return {
          page_access_uid: form.uc_adm_pge_access_uid,
          uid: (form.pge_master_access_url != null && form.pge_master_access_url != "")
          ? form.pge_master_access_url
          : form.pge_frm_type_uid,
          create: form.uc_adm_pge_acs_create === 1 ? true : false,
          read: form.uc_adm_pge_acs_read === 1 ? true : false,
          update: form.uc_adm_pge_acs_update === 1 ? true : false,
          delete: form.uc_adm_pge_acs_delete === 1 ? true : false,
        }
     })

      // RETURN ACCESS ROLE
      return { accessRole, accessSidebar, accessCrud };

    } catch (err) {
      Swal.fire('Error Login', 'Role has not been assign for this module', 'error')
      throw rejectWithValue(err);
    }
  }
);

export const getAppVersion = createAsyncThunk<any, any, { rejectValue: any }>(
  "app-version",
  async ({}, { rejectWithValue }) => {
    try {
      const resp: any = await instanceAdmin.get(`/admin-app-version/latest`);
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const counterSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    removeRejectMassage: (state) => {
      state.rejectMessage = "";
    },
    removeToken: (state) => {
      state.accesToken = "";
    },
    changeLoginLoading: (state) => {
      state.isLoading = false
    },
    deleteErrMsg: (state) => {
      state.errorRoleType = ""
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitLogin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(submitLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.name = action.payload.name;
        state.accesToken = action.payload.data.access;
        state.roleTitle = action.payload.role_title;
      })
      .addCase(submitLogin.rejected, (state, action) => {
        state.rejectMessage = action.payload.response.data.err_details;
        state.isLoading = false;
      })
      .addCase(getRoleType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRoleType.fulfilled, (state, action) => {
        const { accessRole, accessSidebar, accessCrud } = action.payload
        state.isLoading = false;
        state.adminRole = accessRole
        state.accessSidebar = accessSidebar
        state.accessCrud = accessCrud
      })
      .addCase(getRoleType.rejected, (state, action) => {
        state.isLoading = false;
        state.errorRoleType = action.payload.response.data.msg
      })
      .addCase(getAppVersion.pending, (state) => {
      })
      .addCase(getAppVersion.fulfilled, (state, action) => {
        state.appVersion = action.payload.data.uc_admin_app_version_number;
      })
      .addCase(getAppVersion.rejected, (state) => {
      })
  },
});

export const { removeRejectMassage, removeToken, changeLoginLoading, deleteErrMsg } = counterSlice.actions;

export default counterSlice.reducer;
