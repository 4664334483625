import { Box, Typography } from "@mui/material";
import StrucCaCrlsTableCertificate from "../../components/molecules/structureCaCrls/StrucCaCrlsTableCertificate";
import StuctureCaCrlsCertificateSkeleton from "../../components/template/skeleton/StuctureCaCrlsCertificateSkeleton";
import { RootState, useAppSelector } from "../../store";

const StuctureCaCrlsCertificate = () => {
  const { isLoadingCertCAandCRL } = useAppSelector((state: RootState) => state.cerCA);

  return (
    <StuctureCaCrlsCertificateSkeleton isLoading={isLoadingCertCAandCRL} isLoadingEvent={false}>
      <Box paddingY="25px">
        <Typography variant="h3" fontFamily="Open Sans" paddingBottom={2}>
          View CA Information
        </Typography>
        <StrucCaCrlsTableCertificate />
      </Box>
    </StuctureCaCrlsCertificateSkeleton>
  );
};

export default StuctureCaCrlsCertificate;
