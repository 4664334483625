import { Box, Button, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { SelectFormm } from "../../components/molecules/forms";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { useEffect, useState } from "react";
import { downloadCertificate, getDetailCertificate, removeDataDetailCertificate, removeEventFlag, revokeCertificateCaCrls } from "../../store/feature/structureCaCrls";
import Swal from "sweetalert2";
import CertificateInformationSkeleton from "../../components/template/skeleton/CertificateInformationSkeleton";
import { initialAccess } from "../../store/feature/user";
import { useTheme } from '@mui/material/styles';

const SearchCertificateDetail = () => {
  const {palette} = useTheme()
  const dispatch = useAppDispatch();
  const idParam = useParams();
  const [ revokeReason, setRevokeReason ] = useState("")
  const {
    dataDetailCertificate,
    dataDetailCertificateObj,
    isLoadingCertCAandCRL,
    isLoadingEvent,
    eventFlag
  } = useAppSelector((store: RootState) => store.cerCA);
  const [ access, setAccess ] = useState(initialAccess)
  const { accessCrud } = useAppSelector((store: RootState) => store.user);

  const {
    control,
    formState: { errors },
    reset
  } = useForm<{
    status: string;
  }>({
    defaultValues: {
      status: "",
    },
  });

  useEffect(() => {
    const obj = accessCrud.find((item: any) => item.uid === "/search-certificate")
    setAccess(obj != undefined ? obj : initialAccess)
  }, [accessCrud]);

  useEffect(() => {
    if (idParam.id !== undefined) {
      dispatch(getDetailCertificate(idParam.id))
    }
  }, []);
  
  useEffect(() => {
    if(eventFlag) {
      if (idParam.id !== undefined) {
        dispatch(getDetailCertificate(idParam.id))
      }
      dispatch(removeEventFlag())
    }
  }, [eventFlag]);

  useEffect(() => {
    reset({
      status: dataDetailCertificateObj.certificate_revoke_reason !== null ?  dataDetailCertificateObj.certificate_revoke_reason?.toUpperCase().split(' ').join('_') : ''
    })
  }, [dataDetailCertificateObj]);

  const onCertificateDownload = (type: String) => {
    const downloadData = {
      certificateSerialNumber: dataDetailCertificateObj.certificate_serial_number,
      certificateFormat: type
    }
    dispatch(downloadCertificate(downloadData))
  }

  const onSetRevoke = (e : any) => {
    setRevokeReason(e.value)
  }

  const onRevokeCertificate = () => {
    Swal.fire({
      title: 'Revoke Confirmation',
      text: "Are you sure? You won't be able to revert this!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#808080',
      confirmButtonText: 'Yes',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        if(revokeReason != "") {
          const revokeData = {
            certNumber: dataDetailCertificateObj.certificate_serial_number,
            revokeReason: revokeReason
          }
          dispatch(revokeCertificateCaCrls(revokeData))
        } else {
          // Swal.fire('Revoke Failed', 'You need to provide revoke reason', 'error');
          Swal.fire({
            icon: "error",
            title: 'Revoke Failed',
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: palette.primary.main,
            text: 'You need to provide revoke reason',
            didOpen: () => Swal.getConfirmButton()?.focus()
          })
        }
      }
    });
  }

  const optStatusSelect = [
    {
      label: "Unspecified",
      value: "UNSPECIFIED",
    },
    {
      label: "Key Compromise",
      value: "KEY_COMPROMISE",
    },
    {
      label: "CA Compromise",
      value: "CA_COMPROMISE",
    },
    {
      label: "Affiliation Changed",
      value: "AFFLIATION_CHANGED",
    },
    {
      label: "Superseded",
      value: "SUPERSEDED",
    },
    {
      label: "Cessation Of Operation",
      value: "CESSATION_OF_OPERATION",
    },
    {
      label: "Certificate Hold",
      value: "CERTIFICATE_HOLD",
    },
    {
      label: "Remove From CRL",
      value: "REMOVE_FROM_CRL",
    },
    {
      label: "Privilege Withdrawn",
      value: "PRIVILEGE_WITHDRAWN",
    },
    {
      label: "AA Compromise",
      value: "AA_COMPROMISE",
    },
  ];

  const columns: GridColDef[] = [
    {
      field: "label",
      headerName: "Label",
      flex: 1,
      minWidth: 500,
    },
    {
      field: "value",
      headerName: "Value",
      flex: 3,
      minWidth: 500,
      cellClassName: "super-app-theme--header",
      renderCell: (params) => {
        const RenderLongParagraph = () => {
          return (
            <Box
              display="flex"
              alignItems="center"
              minHeight="200px"
              width="100%"
              p={2}
              sx={{ wordBreak: "break-word" }}>
              <Typography variant="body1">{params.value}</Typography>
            </Box>
          );
        };

        const RenderShortParagraph = () => {
          return (
            <Box display="flex" alignItems="center" p={2}>
              <Typography variant="body1">{params.value}</Typography>
            </Box>
          );
        };
        let renderFunction =
          params.value?.length >= 100 ? (
            <RenderLongParagraph />
          ) : (
            <RenderShortParagraph />
          );
        return renderFunction;
      },
    },
  ];

  return (
    <CertificateInformationSkeleton isLoading={isLoadingCertCAandCRL} isLoadingEvent={isLoadingEvent}>
    <Box paddingY="25px">
      <Typography variant="h3" fontFamily="Open Sans" paddingBottom={2}>
        Certificate Information
      </Typography>
      <Stack spacing={2} direction="row" justifyContent="start"  mb={2} alignItems="center">
        { access.read && <>
            <Button variant="contained" onClick={() => { onCertificateDownload('PEM') }}>
              Download as PEM
            </Button>
            {/* <Button variant="contained" onClick={() => { onCertificateDownload('DER') }}>
              Download as DER
            </Button> */}
            </>
        }
        {access.update && <>
            <Typography variant="body1">Revoke reason</Typography>
              <Box sx={{ width: "300px" }}>
                <SelectFormm
                  name="status"
                  defaultValue={1}
                  options={optStatusSelect}
                  placeholder="None"
                  disabled={ dataDetailCertificateObj.certificate_revoke_reason !== null}
                  isLoading={false}
                  control={control}
                  errors={errors}
                  required
                  sxFC={{ marginTop: "7px" }}
                  onChangeForm={onSetRevoke}
                />
              </Box>
            <Button
              variant="contained"
              disabled={ dataDetailCertificateObj.certificate_revoke_reason !== null }
              onClick={ () => { onRevokeCertificate() }}>
                Revoke
            </Button>
          </>
        }
      </Stack>
      <>
      <Box
        sx={{
          height: "600px",
          width: "100%",
          "& .super-app-theme--header": {
            borderLeft: "1px solid #ddd;",
          }
        }}
        marginBottom={100}
        >
        <DataGrid
          rows={dataDetailCertificate}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          autoHeight
          getRowHeight={() => "auto"}
          headerHeight={0}
        />
      </Box>
    </>
    </Box>
    </CertificateInformationSkeleton>
  );
};

export default SearchCertificateDetail;
