
import IRoutes from "../interfaces/IRoutes";
import EmptyPage from "../pages/empty/EmptyPage";
import NotFound from "../pages/notFound/NotFound";
import SignCerReqFCAField from "../pages/signCerReqFCA/SignCerReqFCAField";

const SignceReq: Array<IRoutes> = [
  {
    element: <SignCerReqFCAField />,
    path: "/sign-certificate-request",
  }
];

const SignceReqErr: Array<IRoutes> = [
  {
    element: <NotFound />,
    path: "/sign-certificate-request",
  }
];

const SignceReqEmpty: Array<IRoutes> = [
  {
    element: <EmptyPage />,
    path: "/sign-certificate-request",
  }
];

export { SignceReq, SignceReqErr, SignceReqEmpty };