import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { InputForm, SelectFormm, } from "../../components/molecules/forms/index";
import { useLocation, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { initialAccess } from "../../store/feature/user";
import { importCertificate, removeEventFlag } from "../../store/feature/importCa";
import DynamicDetailSkeleton from "../../components/template/skeleton/DynamicDetailSkeleton";
import importCerCa from "../../utils/validation/importCerCa";
import Swal from "sweetalert2";
import { updateButtonEntry } from "../../store/feature/form";

const ImportCerCAField = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const entryUid = searchParams.get("entry-uid");
  const caProfileLabel = searchParams.get("label");
  const pageAccessUid = searchParams.get("page-access-uid");
  const { isLoading, isLoadingEvent, eventFlag } = useAppSelector((root: RootState) => root.impCa);
  const [fileContent, setFileContent] = useState('');
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    caProfile: string;
    functionMenuName: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(importCerCa),
    defaultValues: {
      caProfile: "",
      functionMenuName: ""
    },
  });

  useEffect(() => {
    console.log("entryUid", entryUid)
    if(entryUid == null) {
      navigate("/")
    }
  }, [entryUid]);

  useEffect(() => {
    if(eventFlag) {
      reset({
        caProfile: "",
        functionMenuName: ""
      })
      setFileContent('')
      dispatch(removeEventFlag())
      window.history.back();
    }
  }, [eventFlag]);

  const handleFileChange = (file: any) => {
    let fileReader = new FileReader();
    fileReader.onloadend = (event: any) => {
      setFileContent(event.target.result.split(',')[1]);
    };
    fileReader.readAsDataURL(file[0]);
  };

  const handleImport = (e: any) => {
    Swal.fire({
      title: "Submit Confirmation",
      text: `Are you sure you want to import certificate?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        const dataImport ={
          crt_byte: fileContent.replace(/^data:[a-z]+\/[a-z]+;base64,/, ''),
          entry_uid: entryUid,
          page_access_uid: pageAccessUid
        }
        dispatch(importCertificate(dataImport));
        dispatch(updateButtonEntry({
          entryId: entryUid,
          params: {
            import_button: "true"
          },
          page_access_uid: pageAccessUid
        }));
      }
    });
  };

  return (
  <DynamicDetailSkeleton
    isLoading={isLoading}
    isLoadingEvent={isLoadingEvent}
    isTitle={false}
    inputCount={4}
    titleWidth={'200px'}>
  <Box component="form" onSubmit={handleSubmit(handleImport)} paddingY="20px">
      <Typography variant="h3" fontFamily="Open Sans" paddingBottom={2}>
        Import Certificate CA
      </Typography>
      <InputForm
        name="caProfile"
        label="CA Profile"
        placeholder={caProfileLabel ?? ''}
        disabled={true}
        isLoading={false}
        control={control}
        errors={errors}
        required
      />
      <InputForm
        name="functionMenuName"
        label="Path to Certificate Signing Request File"
        errors={errors}
        control={control}
        required
        placeholder="Type Function Menu Name Here....."
        maxLength={100}
        isLoading={false}
        type="file"
        onChange={handleFileChange}
      />
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        paddingBottom={2}
        paddingTop={2}
        justifyContent="flex-end">
        <Button
              variant="contained"
              type="submit">
              Submit
            </Button>
      </Stack>
    </Box>
    </DynamicDetailSkeleton>
  );
};

export default ImportCerCAField;
