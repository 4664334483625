import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { instance } from "../../service/instance";

export interface IAdmin {
  isLoadingAdminList? : boolean;
  firtsName: string;
  lastName: string;
  email: string;
  mobile: string;
  createdBy: string;
  createdAt: string;
  lastLogin: string;
  userActive: string;
  roleTitle: string;
  adminList: Array<object>;
}

const initialState: IAdmin = {
  isLoadingAdminList: false,
  firtsName: "",
  lastName: "",
  email: "",
  mobile: "",
  createdBy: "",
  createdAt: "",
  lastLogin: "",
  userActive: "",
  roleTitle: "",
  adminList: [{}],
};

export const getAdminList = createAsyncThunk<any, any, { rejectValue: any }>(
  "admin/list",
  async (data, { rejectWithValue }) => {
    const { firtsName } = data;
    try {
      const resp = await instance.get("/menu/groups");
      console.log('=========> admin slicer', resp.data.data)
      return resp.data.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdminList.pending, (state) => {
        state.isLoadingAdminList = false;
      })
      .addCase(getAdminList.fulfilled, (state, action) => {
        state.isLoadingAdminList = false;
        state.adminList = action.payload;
      })
      .addCase(getAdminList.rejected, (state, action) => {
        state.isLoadingAdminList = false;
      });
  },
});

export const {} = adminSlice.actions;

export default adminSlice.reducer;
