import * as yup from 'yup';
import {
  validationEmailField,
  validationTextField,
} from './defaultValidations';

export default yup.object().shape({
  Email: validationEmailField('Email', 8, 100, 'Your Email is invalid.'),
  Password: validationTextField('Password', 5, 127),
});
