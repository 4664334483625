import { styled, TextField } from '@mui/material';

export const StyledInput = styled(TextField)(({ theme }) => ({
  'label + &': {
    marginTop: '0.5rem',
  },
  '.css-12tl3rr-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
    WebkitTextFillColor: theme.palette.neutral.main,
    color: theme.palette.neutral.main,
  },
  '& .Mui-disabled': {
    fontWeight: 400,
    backgroundColor: theme.palette.gray.light,
    borderRadius: 4,
    WebkitTextFillColor: `${theme.palette.neutral.main} !important`,
    color: `${theme.palette.neutral.main} !important`,
    '& fieldset': {
      borderColor: '#FFF !important',
    },
    '&:hover': {
      cursor: 'no-drop',
    },
  },
  '& .MuiInputBase-input': {
    display: 'flex',
    flex: 1,
    FontFamily: 'Inter',
    borderRadius: 4,
    position: 'relative',
    fontSize: '1rem',
  },
}));
