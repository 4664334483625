
import IRoutes from "../interfaces/IRoutes";
import ImportCerCAField from "../pages/importCerCA/ImportCerCAField";

const importCerCa: Array<IRoutes> = [
  {
    element: <ImportCerCAField />,
    path: "/import-certificate-ca",
  },
];

export default importCerCa;