import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  instance,
  instanceFormEditor,
  instanceNon,
} from "../../service/instance";
import { toPascalCase } from "../../utils/pascalCase";
import { store } from "../index";
import Swal from "sweetalert2";
import { getPageAccessUid } from "../../utils/accessHelper";

export interface ICertCAandCRL {
  isLoadingCertCAandCRL: boolean;
  isLoadingEvent: boolean;
  eventFlag: boolean;
  certCaCrlsList: any;
  totalCertCaCrls: number;
  caCrlsDetails: any;
  dataCAInformation: any;
  dataCerfProfile: any;
  dataCerfAlgorithm: any;
  dataSignUpload: any;
  dataSearchCertificate: any;
  dataDetailCertificate: any;
  dataDetailCertificateObj: any;
  totalKeys: any;
  revokeMsg: string;
}

const initialState: ICertCAandCRL = {
  isLoadingCertCAandCRL: false,
  isLoadingEvent: false,
  eventFlag: false,
  certCaCrlsList: [],
  totalCertCaCrls: 0,
  caCrlsDetails: [],
  dataCAInformation: [],
  dataCerfProfile: [],
  dataCerfAlgorithm: [],
  dataSignUpload: {},
  dataSearchCertificate: [],
  dataDetailCertificate: [],
  dataDetailCertificateObj: {},
  totalKeys: 0,
  revokeMsg: "",
};

const SEARCH_CERT_ACCESS_ID = "/search-certificate"
const SIGN_CERT_ACCESS_ID = "/sign-certificate-request"
const STRUCTURE_CERT_ACCESS_ID = "/ca-structure-and-crls"

export const getCertCaCrls = createAsyncThunk<any, any, { rejectValue: any }>
("certificate/ca-and-crl", async (data, { rejectWithValue, getState }) => {
    try {
      const uid = await getPageAccessUid(getState(), STRUCTURE_CERT_ACCESS_ID)
      const resp = await instanceNon.get(`/list_ca_struct_crl`, {
        params: data,
        headers: {
          'page-access-uid': uid
        }
      });
      const listCert = resp.data.data;
      const certList = listCert.data;
      const totalCertList = listCert.total_records;
      return { certList, totalCertList };
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const getCertCaCrlsDetails = createAsyncThunk<any, any, { rejectValue: any }>
("certificate/ca-and-crl-details", async (id, { rejectWithValue, getState}) => {
  try {
    const uid = await getPageAccessUid(getState(), STRUCTURE_CERT_ACCESS_ID)
    const resp = await instanceNon.get(`/certificate/details/${id}`, {
      headers: {
        'page-access-uid': uid
      }
    });
    const rows = resp.data.data;
    const mappedObj = Object.entries(rows).map(([key, value], index) => {
      // to filtering [], {}, and string
      let valueChange;
      if (Array.isArray(value)) {
        valueChange = value
      } else if (typeof value === 'object') {
        valueChange = JSON.stringify(value).replace(/["{}]/g, '').replace(/:/g, '=');;
      } else {
        valueChange = value
      }
      return {
        id: index + 1,
        label: toPascalCase(key.replace(/_/g, " ")),
        value: valueChange,
      };
    });
    return mappedObj;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const getCertCaCrlsDownloadPem = createAsyncThunk<any, any, { rejectValue: any }>
("certificate/ca-and-crl-download-pem", async (data, { rejectWithValue, getState }) => {
  try {
    const uid = await getPageAccessUid(getState(), SEARCH_CERT_ACCESS_ID)
    const resp = await instanceNon.get(`/certificate/download`, {
      responseType: "blob",
      params: data,
      headers: {
        'page-access-uid': uid
      }
    });
    const url = window.URL.createObjectURL(new Blob([resp.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${data.name}.pem`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return resp.data;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const createcertificateCaStructCrlsById = createAsyncThunk<any, any, { rejectValue: any }>
("service/create-service-definition-id", async (data, { rejectWithValue }) => {
  const { entryId, serDevId } = data;
  try {
    const resp: any = await instanceFormEditor.post(
      `/service/definition/${serDevId}`,
      { entry_id: entryId }
    );
    // return resp.data.data;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const revokeCertificateCaCrls = createAsyncThunk<any, any, { rejectValue: any }>
("certificate/ca-and-crl-revoke-certificate", async (data, { rejectWithValue, getState }) => {
    const { certNumber, revokeReason } = data;
    try {
      const uid = await getPageAccessUid(getState(), SEARCH_CERT_ACCESS_ID)
      const resp: any = await instanceNon.put(`/certificate/${certNumber}`, {
        revoke_reason: revokeReason,
      }, {
        headers: {
          'page-access-uid': uid
        }
      });
      return resp.data.msg;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const generateCrl = createAsyncThunk<any, any, { rejectValue: any }>
("certificate/generate-crl", async (uid, { rejectWithValue, getState }) => {
    try {
      const page_access_uid = await getPageAccessUid(getState(), STRUCTURE_CERT_ACCESS_ID)
      const resp: any = await instanceNon.post(`/gen_crl`, { entry_uid: uid }, {
        headers: {
          'page-access-uid': page_access_uid
        }
      });
      return resp;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const getCAInformation = createAsyncThunk<any, any, { rejectValue: any }>
("certificate/ca-information", async (uid, { rejectWithValue, getState }) => {
  try {
    const page_access_uid = await getPageAccessUid(getState(), STRUCTURE_CERT_ACCESS_ID)
    const resp: any = await instanceNon.get(`/ca_information/${uid}`, {
      headers: {
        'page-access-uid': page_access_uid
      }
    });
    const responseData = resp.data.data.map((row: any, index: number) => ({
      id: index,
      label: toPascalCase(row.param_title.replace(/_/g, " ")),
      value: row.value,
    }));
    return responseData;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const renewCertificate = createAsyncThunk<any, any, { rejectValue: any }>
  ("certificate/renew", async (serialNumber, { rejectWithValue, getState }) => {
  try {
    const uid = await getPageAccessUid(getState(), STRUCTURE_CERT_ACCESS_ID)
    const resp: any = await instanceNon.post(`/renew/certificate/${serialNumber}`, {}, {
        headers: {
          'page-access-uid': uid
        }
      }
    );
    return resp;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const downloadCrl = createAsyncThunk<any, any, { rejectValue: any }>
("certificate/download-crl", async (subjectCn, { rejectWithValue, getState }) => {
    try {
      const uid = await getPageAccessUid(getState(), SEARCH_CERT_ACCESS_ID)
      const resp: any = await instanceNon.get(
        `/crl/download_latest_crl_by_pem`,
        {
          params: {
            cn: subjectCn,
          },
          headers: {
            'page-access-uid': uid
          }
        }
      );
      return {
        payload: resp.data,
        filename: subjectCn,
      };
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const getCertificateRequest = createAsyncThunk<any, any, { rejectValue: any }>
("certificate/certificate-request", async (data, { rejectWithValue, getState }) => {
  try {
    const uid = await getPageAccessUid(getState(), SIGN_CERT_ACCESS_ID)
    const resp: any = await instanceNon.get(`/info_for_certificate_request`, {
      headers: {
        'page-access-uid': uid
      }
    });
    const profileData = resp.data.data.certificate_profile_data.map(
      (item: any) => ({
        label: item.value,
        value: item.entry_uid,
      })
    );
    const algorithmData = resp.data.data.certificate_algorithm_data.map(
      (item: any) => ({
        label: item.value,
        value: item.entry_uid,
      })
    );
    const responseData = {
      profile_data: profileData,
      algorithm_data: algorithmData,
    };
    return responseData;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const uploadCertificate = createAsyncThunk<any, any, { rejectValue: any }>
("certificate/certificate-upload", async (data, { rejectWithValue, getState }) => {
  try {
    const uid = await getPageAccessUid(getState(), SIGN_CERT_ACCESS_ID)
    const resp: any = await instanceNon.post(`/gen_certificate_by_csr`, data, {
      headers: {
        'page-access-uid': uid
      }
    });
    const responseData = resp.data.data.data;
    return responseData;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const downloadCertificate = createAsyncThunk<any, any, { rejectValue: any }>
("certificate/certificate-download", async (data, { rejectWithValue, getState }) => {
  try {
    const uid = await getPageAccessUid(getState(), SEARCH_CERT_ACCESS_ID)
    const resp: any = await instanceNon.get(`/certificate/download`, {
      params: data,
      headers: {
        'page-access-uid': uid
      }
    });
    const responseData = {
      ...data,
      certificateData: resp.data,
    };
    return responseData;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const getSearchCertificate = createAsyncThunk<any, any, { rejectValue: any }>
("certificate/certificate-search", async (data, { rejectWithValue, getState }) => {
  try {
    const uid = await getPageAccessUid(getState(), SEARCH_CERT_ACCESS_ID)
    const resp: any = await instanceNon.get(`/certificates`, {
      params: data,
      headers: {
        'page-access-uid': uid
      }
    });
    const responseData = resp.data.data.data ? resp.data.data.data : [];
    const totalRecords = resp.data.data.total_records;
    return { responseData, totalRecords };
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const getDetailCertificate = createAsyncThunk<any, any, { rejectValue: any }>
  ("certificate/certificate-detail", async (certificateId, { rejectWithValue, getState }) => {
    try {
      const uid = await getPageAccessUid(getState(), SEARCH_CERT_ACCESS_ID)
      const resp: any = await instanceNon.get(`/certificate/details/${certificateId}`, {
        headers: {
          'page-access-uid': uid
        }
      });
      const rows = resp.data.data;
      const mappedObj = Object.entries(rows).map(([key, value], index) => {
        let valueChange;
      if (Array.isArray(value)) {
        valueChange = value
      } else if (typeof value === 'object') {
        valueChange = JSON.stringify(value).replace(/["{}]/g, '').replace(/:/g, '=');;
      } else {
        valueChange = value
      }
      return {
        id: index + 1,
        label: toPascalCase(key.replace(/_/g, " ")),
        value: valueChange,
      };
      });
      return { mappedObj, rows };
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const certificateCaStructCrls = createSlice({
  name: "certificateCaStructCrls",
  initialState,
  reducers: {
    rmvRevokeMsg: (state) => {
      state.revokeMsg = "";
    },
    removeDataCAInformation: (state) => {
      state.dataCAInformation = {};
    },
    removeDataSignUpload: (state) => {
      state.dataSignUpload = {};
    },
    removeDataDetailCertificate: (state) => {
      state.dataDetailCertificate = [];
      state.dataDetailCertificateObj = {};
    },
    setEventFlag: (state) => {
      state.eventFlag = true;
    },
    removeEventFlag: (state) => {
      state.eventFlag = false;
    },
  },
  extraReducers(builder) {
    builder

      // REGION: GET CRL LIST
      .addCase(getCertCaCrls.pending, (state) => {
        state.isLoadingCertCAandCRL = true;
      })
      .addCase(getCertCaCrls.fulfilled, (state, action) => {
        state.isLoadingCertCAandCRL = false;
        state.certCaCrlsList = action.payload.certList;
        state.totalCertCaCrls = action.payload.totalCertList;
      })
      .addCase(getCertCaCrls.rejected, (state) => {
        state.isLoadingCertCAandCRL = false;
        state.certCaCrlsList = [];
        state.totalCertCaCrls = 0;
      })

      // REGION: GET CRL DETAIL
      .addCase(getCertCaCrlsDetails.pending, (state) => {
        state.isLoadingCertCAandCRL = true;
      })
      .addCase(getCertCaCrlsDetails.fulfilled, (state, action) => {
        state.isLoadingCertCAandCRL = false;
        state.caCrlsDetails = action.payload;
      })
      .addCase(getCertCaCrlsDetails.rejected, (state) => {
        state.isLoadingCertCAandCRL = false;
        state.caCrlsDetails = [];
      })

      // REGION: REVOKE CERTIFICATE
      .addCase(revokeCertificateCaCrls.pending, (state) => {
        state.isLoadingEvent = true;
        state.eventFlag = false;
      })
      .addCase(revokeCertificateCaCrls.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
        // Swal.fire({
        //   title: "Revoke Certificate Success",
        //   text: "Certificate is Revoked!",
        //   icon: "success"
        // })
        Swal.fire({
          icon: "success",
          title: "Revoke Certificate Success",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#bfd2e0",
          text: "Certificate is Revoked!",
          didOpen: () => Swal.getConfirmButton()?.focus()
        }).then((_) => {
          store.dispatch(setEventFlag());
        });
      })
      .addCase(revokeCertificateCaCrls.rejected, (state, action) => {
        state.isLoadingEvent = false;
        let message = "Can not Revoke Certificate, Please try again";
        try {
          const errDetail = action.payload.response.data.errors[0].detail;
          if (errDetail != "") message = errDetail;
        } catch (e) {}
        // Swal.fire("Error Revoke Certificate", message, "error")
        Swal.fire({
          icon: "error",
          title: "Error Revoke Certificate",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#bfd2e0",
          text: message,
          didOpen: () => Swal.getConfirmButton()?.focus()
        }).then((_) => {
          store.dispatch(removeEventFlag());
        });
      })

      // REGION: GENERATE CRL
      .addCase(generateCrl.pending, (state) => {
        state.isLoadingEvent = true;
        state.eventFlag = false;
      })
      .addCase(generateCrl.fulfilled, (state) => {
        state.isLoadingEvent = false;
        // Swal.fire({
        //   title: "Generate CRL Success",
        //   text: "CRL is generated",
        //   icon: "success"
        // })
        Swal.fire({
          icon: "success",
          title: "Generate CRL Success",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#bfd2e0",
          text: "CRL is generated",
          didOpen: () => Swal.getConfirmButton()?.focus()
        }).then((_) => {
          store.dispatch(setEventFlag());
        });
      })
      .addCase(generateCrl.rejected, (state, action) => {
        state.isLoadingEvent = false;
        let message = "Can not generate CRL, Please try again";
        try {
          const errDetail = action.payload.response.data.errors[0].detail;
          if (errDetail != "") message = errDetail;
        } catch (e) {}
        // Swal.fire("Error Generate CRL", message, "error")
        Swal.fire({
          icon: "error",
          title: "Error Genereate CRL",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#bfd2e0",
          text: message,
          didOpen: () => Swal.getConfirmButton()?.focus()
        }).then((_) => {
          store.dispatch(removeEventFlag());
        });
      })

      // REGION: GET CA INFORMATION
      .addCase(getCAInformation.pending, (state, action) => {
        state.isLoadingCertCAandCRL = true;
      })
      .addCase(getCAInformation.fulfilled, (state, action) => {
        state.isLoadingCertCAandCRL = false;
        state.dataCAInformation = action.payload;
      })
      .addCase(getCAInformation.rejected, (state, action) => {
        state.isLoadingCertCAandCRL = false;
        state.dataCAInformation = [];
      })

      // REGION: RENEW CERTIFICATE
      .addCase(renewCertificate.pending, (state, action) => {
        state.isLoadingEvent = true;
        state.eventFlag = false;
      })
      .addCase(renewCertificate.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
        // Swal.fire({
        //   title: "Renew Certificate Success",
        //   text: "Certificate is Renewed!",
        //   icon: "success"
        // })
        Swal.fire({
          icon: "success",
          title: "Certificate is Renewed!",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#bfd2e0",
          text: "Certificate is Renewed!",
          didOpen: () => Swal.getConfirmButton()?.focus()
        }).then((_) => {
          store.dispatch(setEventFlag());
        });
      })
      .addCase(renewCertificate.rejected, (state, action) => {
        state.isLoadingEvent = false;
        let message = "Can not Renew Certificate, Please try again";
        try {
          const errDetail = action.payload.response.data.errors[0].detail;
          if (errDetail != "") message = errDetail;
        } catch (e) {}
        // Swal.fire("Error Renew Certificate", message, "error")
        Swal.fire({
          icon: "error",
          title: "Error Renew Certificate",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#bfd2e0",
          text: message,
          didOpen: () => Swal.getConfirmButton()?.focus()
        }).then((_) => {
          store.dispatch(removeEventFlag());
        });
      })

      // REGION: DOWNLOAD CRL
      .addCase(downloadCrl.pending, (state, action) => {
        state.isLoadingEvent = true;
      })
      .addCase(downloadCrl.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
        const { payload, filename } = action.payload;
        if (typeof payload === "string") {
          const url = window.URL.createObjectURL(new Blob([payload]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${filename}.crl`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          // Swal.fire({
          //   title: "Success Download",
          //   text: "Your CRL is being downloaded!",
          //   icon: "success",
          // });
          Swal.fire({
            icon: "success",
            title: "Success Download",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: "#bfd2e0",
            text: "Your CRL is being downloaded!",
            didOpen: () => Swal.getConfirmButton()?.focus()
          });
        } else {
          const errMessage = payload.errors.detail;
          // Swal.fire({
          //   title: "Failed Download",
          //   text: errMessage,
          //   icon: "error",
          // });
          Swal.fire({
            icon: "error",
            title: "Failed Download",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: "#bfd2e0",
            text: errMessage,
            didOpen: () => Swal.getConfirmButton()?.focus()
          });
        }
      })
      .addCase(downloadCrl.rejected, (state, action) => {
        state.isLoadingEvent = false;
        let message = "Can not Download CRL, Please try again";
        try {
          const errDetail = action.payload.response.data.errors[0].detail;
          if (errDetail != "") message = errDetail;
        } catch (e) {}
        // Swal.fire("Error Download CRL", message, "error")
        Swal.fire({
          icon: "error",
          title: "Error Download CRL",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#bfd2e0",
          text: message,
          didOpen: () => Swal.getConfirmButton()?.focus()
        }).then((_) => {
          store.dispatch(removeEventFlag());
        });
      })

      // REGION: GET CERTIFICATE DETAIL
      .addCase(getCertificateRequest.pending, (state, action) => {})
      .addCase(getCertificateRequest.fulfilled, (state, action) => {
        const { profile_data, algorithm_data } = action.payload;
        state.dataCerfProfile = profile_data;
        state.dataCerfAlgorithm = algorithm_data;
      })
      .addCase(getCertificateRequest.rejected, (state) => {
        state.dataCerfProfile = [];
        state.dataCerfAlgorithm = [];
      })

      // REGION: UPLOAD CERTIFICATE
      .addCase(uploadCertificate.pending, (state, action) => {
        state.isLoadingEvent = true;
        state.eventFlag = false;
      })
      .addCase(uploadCertificate.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
        state.dataSignUpload = action.payload;
        // Swal.fire({
        //   title: "Sign Certificate Success",
        //   text: "The CSR has been successfully signed!",
        //   icon: "success",
        // });
        Swal.fire({
          icon: "success",
          title: "Sign Certificate Success",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#bfd2e0",
          text: "The CSR has been successfully signed!",
          didOpen: () => Swal.getConfirmButton()?.focus()
        })
      })
      .addCase(uploadCertificate.rejected, (state, action) => {
        state.isLoadingEvent = false;
        let message = "Can not Upload Certificate, Please try again";
        try {
          const errDetail = action.payload.response.data.errors[0].detail;
          if (errDetail != "") message = errDetail;
        } catch (e) {}
        // Swal.fire("Error Upload Certificate", message, "error")
        Swal.fire({
          icon: "error",
          title: "Error Upload Certificate",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#bfd2e0",
          text: message,
          didOpen: () => Swal.getConfirmButton()?.focus()
        }).then((_) => {
          store.dispatch(removeEventFlag());
        });
      })

      // REGION: DOWNLOAD CERTIFICATE
      .addCase(downloadCertificate.pending, (state, action) => {
        state.isLoadingEvent = true;
      })
      .addCase(downloadCertificate.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
        const { certificateFormat, certificateSerialNumber, certificateData } =
          action.payload;
        const url = window.URL.createObjectURL(new Blob([certificateData]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${certificateSerialNumber}.${certificateFormat.toLowerCase()}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // Swal.fire({
        //   title: "Download Certificate Success",
        //   text: "Your Certificate is being downloaded!",
        //   icon: "success",
        // });
        Swal.fire({
          icon: "success",
          title: "Download Certificate Success",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#bfd2e0",
          text: "Your Certificate is being downloaded!",
          didOpen: () => Swal.getConfirmButton()?.focus()
        })
      })
      .addCase(downloadCertificate.rejected, (state, action) => {
        state.isLoadingEvent = false;
        let message = "Can not Download Certificate, Please try again";
        try {
          const errDetail = action.payload.response.data.errors[0].detail;
          if (errDetail != "") message = errDetail;
        } catch (e) {}
        // Swal.fire("Error Download Certificate", message, "error")
        Swal.fire({
          icon: "error",
          title: "Error Download Certificate",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#bfd2e0",
          text: message,
          didOpen: () => Swal.getConfirmButton()?.focus()
        }).then((_) => {
          store.dispatch(removeEventFlag());
        });
      })

      // REGION: GET SEARCH CERTIFICATE
      .addCase(getSearchCertificate.pending, (state, action) => {
        state.isLoadingCertCAandCRL = true;
        state.dataSearchCertificate = [];
      })
      .addCase(getSearchCertificate.fulfilled, (state, action) => {
        state.isLoadingCertCAandCRL = false;
        const { responseData, totalRecords } = action.payload;
        state.dataSearchCertificate = responseData;
        state.totalKeys = totalRecords;
      })
      .addCase(getSearchCertificate.rejected, (state, action) => {
        state.isLoadingCertCAandCRL = false;
      })

      // REGION: GET CERTIFICATE DETAIL
      .addCase(getDetailCertificate.pending, (state, action) => {
        state.isLoadingCertCAandCRL = true;
      })
      .addCase(getDetailCertificate.fulfilled, (state, action) => {
        state.isLoadingCertCAandCRL = false;
        const { mappedObj, rows } = action.payload;
        state.dataDetailCertificate = mappedObj;
        state.dataDetailCertificateObj = rows;
      })
      .addCase(getDetailCertificate.rejected, (state, action) => {
        state.isLoadingCertCAandCRL = false;
      });
  },
});

export const {
  rmvRevokeMsg,
  removeDataCAInformation,
  removeDataSignUpload,
  removeDataDetailCertificate,
  setEventFlag,
  removeEventFlag,
} = certificateCaStructCrls.actions;
export default certificateCaStructCrls.reducer;
