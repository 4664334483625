import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import DynamicField from '../../components/organism/dynamicPage/DynamicField';
import DynamicTable from '../../components/organism/dynamicPage/DynamicTable';

const DynamicPage = () => {
  const { pathname } = useLocation();
  const isCreate = pathname.includes('create');
  const isEdit = pathname.includes('edit');

  return (
    <Box>
      {!isCreate && !isEdit && <DynamicTable />}
      <Box sx={{
          // paddingX: '150px',
          paddingTop: '20px'
        }}>
        {isCreate && <DynamicField />}
        {isEdit && <DynamicField />}
      </Box>
    </Box>
  );
};

export default DynamicPage;
