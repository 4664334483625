import { validationArraySelect, validationNotEmpty, validationNullable } from "./defaultValidations";

export const dynamicYup = (forms: any) => {
    let tempObject: any = {};
    forms.map((form: any) => {
        if(form.form_param_mandatory) {
            tempObject[form.form_param_id] = typeFormValidator(form)
            // console.log("FORM: ", form.form_param_mandatory, form.form_param_id, form.form_param_field_type, form.form_param_title)
        } else {
            // console.log("FORM: ", form.form_param_mandatory, form.form_param_id, form.form_param_field_type, form.form_param_title)
        }
    })
    // console.log("===========================================================")
    return tempObject
}

const typeFormValidator = (form: any) => {
    switch(form.form_param_field_type) {

        // Uneditable field = 00
        case "00": {
            return validationNullable()
        }

        // Input textfield = 01
        case "01": {
            return validationNotEmpty(form.form_param_title)
        }

        // Dropdown = 02
        case "02": {
            return validationNotEmpty(form.form_param_title)
        }

        // Multiple choice = 03
        case "03": {
            return validationArraySelect(form.form_param_title)
        }

        // Checkbox = 04
        case "04": {
            return validationNotEmpty(form.form_param_title)
        }

        // Textbox = 05
        case "05": {
            return validationNotEmpty(form.form_param_title)
        }

        // Separator = 06
        case "06": {
            return validationNotEmpty(form.form_param_title)
        }

        // Button = 07
        // tidak terpakai
        case "07": {
            return validationNotEmpty(form.form_param_title)
        }

        // textfield = 08
        // Input grid buat skip value untuk di post
        case "08": {
            return validationNotEmpty(form.form_param_title)
        }

        // Multiple Checkbox = 09
        case "09": {
            return validationNotEmpty(form.form_param_title)
        }

        // Multiple Radio Button = 10
        case "10": {
            return validationNotEmpty(form.form_param_title)
        }

        // Grid = 11
        // berbentuk table dgn tombol delete, dan tombol add d luar table
        case "11": {
            return validationNotEmpty(form.form_param_title)
        }

        // Default
        default: { 
            return validationNullable()
        }

     }
}