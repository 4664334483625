import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, RootState } from '../../store';
import { useNavigate } from 'react-router-dom';
import SearchCertificateTable from '../../components/molecules/searchCertificate/SearchCertificateTable';
import { getSearchCertificate } from '../../store/feature/structureCaCrls';
import ListDataSkeleton from '../../components/template/skeleton/ListDataSkeleton';
import SearchUserCertificateSearch from '../../components/molecules/searchUserCertificate/SearchUserCertificateSearch';

const SearchUserCertificate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({
    isUser: "10000001" // TRUE
  });
  const [defaultValue, setDefaultValue] = useState({});
  const { dataSearchCertificate, isLoadingCertCAandCRL } = useAppSelector((state: RootState) => state.cerCA);

  useEffect(() => {
    dispatch(getSearchCertificate(params))
  }, [params]);

  return (
    <ListDataSkeleton isLoading={isLoadingCertCAandCRL} isLoadingEvent={false} colCount={6} titleWidth={'275px'} contentHeight={650}>
      <Typography variant="h3" fontFamily="Open Sans" sx={{ marginY: '20px' }}>
        Search User Certificate
      </Typography>
      <SearchUserCertificateSearch
        params={params}
        setParams={setParams}
      />
      <SearchCertificateTable
        data={dataSearchCertificate}
        params={params}
        setParams={setParams}
        defaultValue={defaultValue}
        setDefaultValue={setDefaultValue}
      />
      {/* <Toast
        severity="success"
        open={msgSccsFGr !== ''}
        handleClose={handleCloseToast}
        message={msgSccsFGr}
      /> */}
    </ListDataSkeleton>
  );
};

export default SearchUserCertificate;
