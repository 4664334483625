import { ISidebar } from '../interfaces/ISidebar';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone'; // dashboard
import DevicesTwoToneIcon from '@mui/icons-material/DevicesTwoTone'; // device
import CardMembershipTwoToneIcon from '@mui/icons-material/CardMembershipTwoTone'; // usercertificate
import BadgeTwoToneIcon from '@mui/icons-material/BadgeTwoTone'; // officer sertificate
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import ContactsIcon from '@mui/icons-material/Contacts';

export const arrSidebarFirst: Array<ISidebar> = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    path: '/',
    icon: DashboardTwoToneIcon,
    child: [],
  },
];

export const arrSidebar: Array<ISidebar> = [
  {
    key: 'ca-structure-and-crls',
    label: 'CA Structure & CRLs',
    path: 'ca-structure-and-crls',
    icon: DevicesTwoToneIcon,
    child: [],
  },
  {
    key: 'sign-certificate-request',
    label: 'Sign Certificate Request',
    path: 'sign-certificate-request',
    icon: CardMembershipTwoToneIcon,
    child: [],
  },
  {
    key: 'search-certificate',
    label: 'Search Certificate',
    path: 'search-certificate',
    icon: BadgeTwoToneIcon,
    child: [],
  },
  {
    key: 'search-user-certificate',
    label: 'Search User Certificate',
    path: 'search-user-certificate',
    icon: ContactsIcon,
    child: [],
  },
  {
    key: 'import-certificate-ca',
    label: 'Import Certificate CA',
    path: 'import-certificate-ca',
    icon: ImportantDevicesIcon,
    child: [],
  },
];
