import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../service/instance";
import certProf from "../../utils/datajson/cerProf.json";

interface IcerPof {
  data: any;
  formParams: any;
  defaultVal: any;
  formTypes: any;
  loadingCerProf: boolean;
  paramIdWithOpt: any;
  dictValueId: any;
  dictParamsUid: any;
}

const initialState: IcerPof = {
  data: [],
  formParams: [],
  defaultVal: [],
  formTypes: [],
  loadingCerProf: false,
  paramIdWithOpt: [],
  dictValueId: {},
  dictParamsUid: {}
};

export const getCerProf = createAsyncThunk<any, any, { rejectValue: any }>(
  "cerProf",
  async ({id, page_access_uid}, { rejectWithValue }) => {
    try {
      const resp = await instance.get(`/form/${id}`, { 
        headers: {
          'page-access-uid': page_access_uid
          }
       });
      return resp.data.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// Form Entry

export const getFormEntryList = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("FormEntry/list-update", async ({id, page_access_uid}, { rejectWithValue }) => {
  try {
    const resp: any = await instance.get(`/entry/${id}`, { 
      headers: {
        'page-access-uid': page_access_uid
        }
     });
    return resp.data.data;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const cerProfSlice = createSlice({
  name: "cerProf",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCerProf.pending, (state) => {
        state.loadingCerProf = true;
        state.defaultVal = {};
        state.paramIdWithOpt = [];
        state.dictParamsUid = {};
      })
      .addCase(getCerProf.fulfilled, (state, action) => {
        state.loadingCerProf = false;
        state.formParams = action.payload.form_params;
        state.formTypes = action.payload.form_type;
        let tempVal: any = {};
        let withOpt: any = [];
        let dict: any = {};
        try {
          action.payload.form_params.sort((a:any, b:any) => a.form_param_order_position - b.form_param_order_position)
        } catch(e) { }
        action.payload.form_params.forEach((e: any) => {
          let defVal: any = ''
          if(e.form_param_field_type === '04'){
            defVal = false
          } else {
            defVal = e.form_param_default_value === null ? "" : e.form_param_default_value
          }
           
          tempVal[e.form_param_id] = defVal;
          dict[e.form_param_id] = e.form_param_uid
          if (e.form_param_option_values !== null)
            withOpt.push(e.form_param_id);
        });
        console.log('with opt from store ===> ', withOpt)
        state.defaultVal = tempVal;
        state.paramIdWithOpt = withOpt;
        state.dictParamsUid = dict
      })
      .addCase(getCerProf.rejected, (state) => {
        state.loadingCerProf = false;
        state.defaultVal = {};
        state.paramIdWithOpt = [];
        state.dictParamsUid = {};
      })

      .addCase(getFormEntryList.pending, (state) => {
        state.loadingCerProf = true;
        state.defaultVal = {};
        state.paramIdWithOpt = [];
        state.dictParamsUid = {};
      })
      .addCase(getFormEntryList.fulfilled, (state, action) => {
        state.loadingCerProf = false;
        state.formParams = action.payload.form_params;
        state.formTypes = action.payload.form_type;
        let tempVal: any = {};
        let withOpt: any = [];
        let dict: any = {};
        try {
          action.payload.form_params.sort((a:any, b:any) => a.form_param_order_position - b.form_param_order_position)
        } catch(e) { }
        action.payload.form_params.forEach((e: any) => {
          if (e.form_param_values !== null) {
            if (e.form_param_field_type === "03") {
              tempVal[e.form_param_id] = e.form_param_values.map((e: any) => ({
                value: `${e.option_value_id}~${e.label}`,
                label: e.label,
              }));
            } else if (e.form_param_field_type === "09") {
              //(`${e.option_value_id}-${e.label}`)
              tempVal[e.form_param_id] = e.form_param_values.map(
                (e: any) => "1264-Multiplecheckbox 3"
              );
              // "1265-Multiplecheckbox 4",
              // "1262-Multiplecheckbox 1"]))
              // tempVal[e.form_param_uid] = ["1264-Multiplecheckbox 3", "1265-Multiplecheckbox 4"]
            } else if (e.form_param_field_type === "04") {
              try {
                tempVal[e.form_param_id] = e.form_param_values[0].label === 'true' ? true : false
              } catch(err) {
                tempVal[e.form_param_id] = false
              }
            }
             else if (
              e.form_param_field_type === "00" ||
              e.form_param_field_type === "01" ||
              e.form_param_field_type === "05"
            ) {
              tempVal[`${e.form_param_id}`] = e.form_param_values.map(
                (e: any) => e.label
              )[0];
            } else {
              tempVal[`${e.form_param_id}`] = e.form_param_values.map(
                (e: any) => `${e.option_value_id}~${e.label}`
              )[0];
            }
            let obj: any;
            e.form_param_values.forEach((e: any) => (obj = e.uid));
            state.dictValueId[`${e.form_param_id}`] = obj;
          } else {
            if (e.form_param_field_type === "03") {
              tempVal[e.form_param_id] = []
            }
          }
          if (e.form_param_option_values !== null) {
            withOpt.push(e.form_param_id);
          }
          dict[e.form_param_id] = e.form_param_uid
        });
        state.defaultVal = tempVal;
        state.paramIdWithOpt = withOpt;
        state.dictParamsUid = dict
        //   .map((e: any) => ({
        //     // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        //     [e.form_param_id] : e.form_param_default_value
        // //   }))
        // console.log(" here from tempval Edit ====> ", tempVal);
        // console.log(" here from withOpt Edit ====> ", withOpt);
      })
      .addCase(getFormEntryList.rejected, (state, action) => {
        state.loadingCerProf = false;
        state.defaultVal = {};
        state.paramIdWithOpt = [];
        state.dictParamsUid = {};
      });
  },
});

export const {} = cerProfSlice.actions;

export default cerProfSlice.reducer;
