
import IRoutes from "../interfaces/IRoutes";
import EmptyPage from "../pages/empty/EmptyPage";
import NotFound from "../pages/notFound/NotFound";
import SearchUserCertificate from "../pages/searchUserCertificate/SearchUserCertificate";

const SearchUserCertificateReq: Array<IRoutes> = [
  {
    element: <SearchUserCertificate />,
    path: "/search-user-certificate",
  }
];

const SearchUserCertificateReqErr: Array<IRoutes> = [
  {
    element: <NotFound />,
    path: "/search-user-certificate",
  }
];

const SearchUserCertificateReqEmpty: Array<IRoutes> = [
  {
    element: <EmptyPage />,
    path: "/search-user-certificate",
  }
];

export { SearchUserCertificateReq, SearchUserCertificateReqErr, SearchUserCertificateReqEmpty };