import React from 'react';
import {
  FormControl,
  FormHelperText,
  FormControlLabel,
  Box,
  useTheme,
  Skeleton,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import FormLabel from '../../../atoms/formLabel/index';
import SwitchButton from '../../../atoms/switchButton/index';

interface Iprops {
  sxFC?: any;
  sxFL?: any;
  name: string;
  label: string;
  labelValue: string;
  errors?: any;
  control?: any;
  defaultValue: any;
  disabled?: boolean;
  required?: boolean;
  onChange?: Function;
  isLoading?: boolean;
}

export default function SwitchForm(props: Iprops) {
  const {
    sxFC,
    sxFL,
    name,
    label,
    control,
    defaultValue,
    errors,
    labelValue,
    disabled,
    required = false,
    onChange = () => {},
    isLoading = false,
  } = props;
  const { palette } = useTheme();
  return (
    <FormControl
      sx={{ display: 'flex', mb: '1.5rem', ...sxFC }}
      variant="standard"
    >
      <Box sx={{ flexDirection: 'column', display: 'flex' }}>
        {label !== '' && (
          <FormLabel
            htmlFor={name}
            sx={{ color: `${palette.neutral.dark} !important`, ...sxFL }}
          >
            {label}{' '}
            {!required && !disabled && (
              <span
                style={{
                  marginLeft: '4px',
                  color: palette.neutral.light,
                  fontWeight: 400,
                  fontSize: '1rem',
                  lineHeight: '1.5rem',
                  fontStyle: 'italic',
                }}
              >
                (optional)
              </span>
            )}
          </FormLabel>
        )}
        {!isLoading && (
          <Controller
            defaultValue={defaultValue}
            control={control}
            name={name}
            render={({ field }) => (
              <FormControlLabel
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: '1rem',
                    fontWeight: 400,
                    lineHeight: '1.5rem',
                    WebkitTextFillColor: palette.gray.dark,
                    color: palette.gray.dark,
                  },
                  '&.Mui-disabled': {
                    WebkitTextFillColor: palette.gray.dark,
                    color: palette.gray.dark,
                    '&:hover': {
                      cursor: 'no-drop',
                    },
                  },
                  marginLeft: 0,
                  marginRight: 0,
                  paddingTop: '0.5rem',
                }}
                control={
                  <SwitchButton
                    {...field}
                    disabled={disabled}
                    checked={!!field.value}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                      const isChecked = ev.target.checked;
                      field.onChange(isChecked);
                      onChange(isChecked);
                    }}
                  />
                }
                label={labelValue}
              />
            )}
          />
        )}
      </Box>
      {isLoading && (
        <Box display="flex" sx={{ marginTop: '7px' }}>
          <Skeleton
            style={{
              height: '24px',
              width: '40px',
              borderRadius: '12px',
              marginRight: '8px',
            }}
          />
          <Skeleton style={{ height: '24px', width: '80px' }} />
        </Box>
      )}
      <FormHelperText
        sx={{ fontSize: '0.75rem', color: palette.secondary.main }}
      >
        {errors[name] && errors[name].message}
      </FormHelperText>
    </FormControl>
  );
}
