import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instanceNon } from "../../service/instance";
import { store } from '../index';
import Swal from "sweetalert2";
import { getPageAccessUid } from "../../utils/accessHelper";

export interface IMenu {
    isLoading: boolean;
    isLoadingEvent: boolean;
    eventFlag: boolean;
    dataCaProfile: any;
}

const initialState: IMenu = {
  isLoading: false,
  isLoadingEvent: false,
  eventFlag: false,
  dataCaProfile: []
}

export const getInfoCaExternal = createAsyncThunk<any, any, { rejectValue: any }>(
    "info_ca_external",
    async (data, { rejectWithValue }) => {
      try {
        const resp = await instanceNon.get("/info_ca_external");
        const data = resp.data.data.ca_external_data.map((item: any) => {
          return {
            label: item.value,
            value: item.entry_uid, 
          }
        })
        return data;
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );

  export const importCertificate = createAsyncThunk<any, any, { rejectValue: any }>(
    "import/certificate",
    async (data, { rejectWithValue, getState }) => {
      try {
        const uid = await getPageAccessUid(getState(), "/Dashboard")
        const resp = await instanceNon.post("/import/certificate", data, {
          headers: {
            'page-access-uid': uid
          }
        });
        return resp;
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );
  

export const importCaSlice = createSlice({
    name: "importCa",
    initialState,
    reducers: {
      setEventFlag: (state) => {
        state.eventFlag = true;
      },
      removeEventFlag: (state) => {
        state.eventFlag = false;
      }
    },
    extraReducers(builder) {
        builder

        // REGION: GET INFO CA EXTERNAL
        .addCase(getInfoCaExternal.pending, (state)=> {
          state.isLoading = true
        })
        .addCase(getInfoCaExternal.fulfilled, (state, action)=> {
          state.isLoading = false
          state.dataCaProfile = action.payload
        })
        .addCase(getInfoCaExternal.rejected, (state)=> {
          state.isLoading = false
          state.dataCaProfile = []
        })

        // REGION: IMPORT CERTIFICATE
        .addCase(importCertificate.pending, (state)=> {
          state.isLoadingEvent = true
          state.eventFlag =  false;
        })
        .addCase(importCertificate.fulfilled, (state, action)=> {
          state.isLoadingEvent = false
          // Swal.fire({
          //   title: "Import Certificate Success",
          //   text: "The file has been successfully imported!",
          //   icon: "success",
          // })
          Swal.fire({
            icon: "success",
            title:"Import Certificate Success",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: '#bfd2e0',
            text: "The file has been successfully imported!",
            didOpen: () => Swal.getConfirmButton()?.focus()
          }).then((_) => {
            store.dispatch(setEventFlag())
          });
        })
        .addCase(importCertificate.rejected, (state, action)=> {
          state.isLoadingEvent = false
          let message = "Can not Import Certificate, Please try again"
          try {
            const errDetail = action.payload.response.data.errors[0].detail
            if(errDetail != "") message = errDetail
          } catch(e) {}
          // Swal.fire("Error Import Certificate", message, "error")
          Swal.fire({
            icon: "error",
            title: "Error Import Certificate",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: '#bfd2e0',
            text: message,
            didOpen: () => Swal.getConfirmButton()?.focus()
          }).then((_) => {
            store.dispatch(removeEventFlag())
          });
        })


    },
})

export const { setEventFlag, removeEventFlag } = importCaSlice.actions;
export default importCaSlice.reducer;