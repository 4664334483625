
import IRoutes from "../interfaces/IRoutes";
import EmptyPage from "../pages/empty/EmptyPage";
import NotFound from "../pages/notFound/NotFound";
import StructureCaCrlsTable from "../pages/structureCaCrls/StructureCaCrls";
import StructureCaCrlsInfomation from "../pages/structureCaCrls/StructureCaCrlsInformation";
import StructureCaCrlsCertificate from "../pages/structureCaCrls/StuctureCaCrlsCertificate";

const StructureCaCrls: Array<IRoutes> = [
  {
    element: <StructureCaCrlsTable />,
    path: "/ca-structure-and-crls",
  },
  {
    element: <StructureCaCrlsInfomation />,
    path: "/ca-structure-and-crls/certificate",
  },
  {
    element: <StructureCaCrlsCertificate />,
    path: "/ca-structure-and-crls/information/:id",
  },
];

const StructureCaCrlsErr: Array<IRoutes> = [
  {
    element: <NotFound />,
    path: "/ca-structure-and-crls",
  },
  {
    element: <NotFound />,
    path: "/ca-structure-and-crls/certificate",
  },
  {
    element: <NotFound />,
    path: "/ca-structure-and-crls/information/:id",
  },
];

const StructureCaCrlsEmpty: Array<IRoutes> = [
  {
    element: <EmptyPage />,
    path: "/ca-structure-and-crls",
  },
  {
    element: <EmptyPage />,
    path: "/ca-structure-and-crls/certificate",
  },
  {
    element: <EmptyPage />,
    path: "/ca-structure-and-crls/information/:id",
  },
];

export { StructureCaCrls, StructureCaCrlsErr, StructureCaCrlsEmpty };