export const toPascalCase = (str: string) => {
if (str === null || str === undefined) {
    return 
}
  // convert string to lowercase and split into an array of words
  let words = str.toLowerCase().split(" ");

  // iterate over the words array and capitalize the first letter of each word
  
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }

  // join the words array into a single string with no spaces
  return words.join(" ");
};
