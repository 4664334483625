import React from "react";
import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    label: React.CSSProperties;
    subHeading: React.CSSProperties;
    body14Semibold: React.CSSProperties;
    body14Regular: React.CSSProperties;
    body16Regular: React.CSSProperties;
    body12Regular: React.CSSProperties;
    paragraph14Regular: React.CSSProperties;
    caption10Regular: React.CSSProperties;
    caption12Regular: React.CSSProperties;
    caption8SemiBold: React.CSSProperties;
    body12Semibold: React.CSSProperties;
    heading: React.CSSProperties;
    heading2: React.CSSProperties;
    h5SemiBold: React.CSSProperties;
    subHeadingSemiBold: React.CSSProperties;
    body12Bold: React.CSSProperties;
    caption8Bold: React.CSSProperties;
    caption8Regular: React.CSSProperties;
    paragraph2: React.CSSProperties;
    paragraph16Regular: React.CSSProperties;
    buttonLarge: React.CSSProperties;
    buttonSmall: React.CSSProperties;
    caption: React.CSSProperties;
    overline: React.CSSProperties;
    Subtitle16Bold: React.CSSProperties;
    subtitle16Regular: React.CSSProperties;
    subtitle18SemiBold: React.CSSProperties;
    h2bold: React.CSSProperties;
    h5bold: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    label?: React.CSSProperties;
    subHeading: React.CSSProperties;
    body14Semibold: React.CSSProperties;
    body14Regular: React.CSSProperties;
    body12Regular: React.CSSProperties;
    paragraph14Regular: React.CSSProperties;
    caption10Regular: React.CSSProperties;
    caption8SemiBold: React.CSSProperties;
    caption12Regular: React.CSSProperties;
    body12Semibold: React.CSSProperties;
    h5SemiBold: React.CSSProperties;
    heading: React.CSSProperties;
    heading2: React.CSSProperties;
    subHeadingSemiBold: React.CSSProperties;
    body12Bold: React.CSSProperties;
    caption8Bold: React.CSSProperties;
    caption8Regular: React.CSSProperties;
    paragraph1: React.CSSProperties;
    paragraph2: React.CSSProperties;
    paragraph16Regular: React.CSSProperties;
    buttonLarge: React.CSSProperties;
    buttonSmall: React.CSSProperties;
    caption: React.CSSProperties;
    overline: React.CSSProperties;
    Subtitle16Bold: React.CSSProperties;
    subtitle16Regular: React.CSSProperties;
    subtitle18SemiBold: React.CSSProperties;
    body16semibold: React.CSSProperties;
    body16Regular: React.CSSProperties;
    h2bold: React.CSSProperties;
    h5bold: React.CSSProperties;
  }
}
// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    label: true;
    subHeading: true;
    body14Semibold: true;
    body14Regular: true;
    body12Regular: true;
    paragraph14Regular: true;
    caption10Regular: true;
    caption12Regular: true;
    caption8SemiBold: true;
    body12Semibold: true;
    heading: true;
    heading2: true;
    subHeadingSemiBold: true;
    body12Bold: true;
    caption8Bold: true;
    caption8Regular: true;
    paragraph1: true;
    paragraph2: true;
    paragraph16Regular: true;
    buttonLarge: true;
    buttonSmall: true;
    caption: true;
    overline: true;
    body16semibold: true;
    body16Regular: true;
    h2bold: true;
    h5bold: true;
    subtitle16Regular: true;
    Subtitle16Bold: true;
    subtitle18SemiBold: true;
    h5SemiBold: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
    gray: Palette["primary"];
    white: {
      darker: string;
      main: string;
      dark: string;
    };
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions["primary"];
    gray?: PaletteOptions["primary"];
    white?: {
      darker: string;
      main: string;
      dark: string;
    };
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
    gray: true;
    white: true;
  }
}

// '#051438'
export default createTheme({
  palette: {
    primary: {
      light: "#EDF5FF",
      main: "#bfd2e0",
      dark: "#003C80",
    },
    secondary: {
      light: "#FFF3F5",
      main: "#ED1A39",
      dark: "#C0021E",
    },
    error: {
      light: "#FEF3F2",
      main: "#F04438",
      dark: "#B42318",
    },
    warning: {
      light: "#FFFAEB",
      main: "#F79009",
      dark: "#B54708",
    },
    success: {
      light: "#ECFDF3",
      main: "#12B76A",
      dark: "#027A48",
    },
    neutral: {
      light: "#B6B6B6",
      main: "#3D3D3D",
      dark: "#212028",
    },
    gray: {
      light: "#EBEBEB",
      main: "#E0E0E0",
      dark: "#8D8D8D",
    },
    white: {
      darker: "#5d7694",
      main: "#FFFFFF",
      dark: "#FAFAFA",
    },
  },
  typography: {
    button: {
      textTransform: "none",
      cursor: "pointer",
    },
    fontFamily: [
      "Open Sans",
      "sans-serif",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    label: {
      fontSize: "0.625rem",
      color: "#B6B6B6",
    },
    subHeading: {
      fontSize: "1rem",
      color: "#000000",
      fontWeight: 600,
    },
    body14Semibold: {
      fontSize: "0.875rem",
      fontWeight: 600,
      color: "#000000",
    },
    body14Regular: {
      fontSize: "0.875rem",
      color: "#000000",
    },
    body12Regular: {
      fontSize: "0.75rem",
      color: "#000000",
    },
    caption10Regular: {
      fontSize: "0.625rem",
      color: "#000000",
    },
    caption12Regular: {
      fontSize: "12px",
      lineHeight: "18px",
      letterSpacing: "0.4px",
    },
    caption8SemiBold: {
      fontSize: "0.5rem",
      fontWeight: 600,
      color: "#000000",
    },
    body12Semibold: {
      fontSize: "0.75rem",
      fontWeight: 600,
      color: "#000000",
    },
    heading: {
      fontSize: "1.5rem",
      color: "#000000",
      fontWeight: "bold",
    },
    heading2: {
      fontSize: "1.25rem",
      color: "#000000",
      fontWeight: 700,
    },
    subHeadingSemiBold: {
      fontSize: "1rem",
      color: "#000000",
      fontWeight: 400,
    },
    body12Bold: {
      fontSize: "0.75rem",
      fontWeight: 700,
      color: "#000000",
    },
    caption8Bold: {
      fontSize: "0.5rem",
      fontWeight: 700,
      color: "#000000",
    },
    caption8Regular: {
      fontSize: "0.5rem",
      color: "#000000",
    },
    h1: {
      fontWeight: "400",
      fontSize: "3.75rem",
      letterSpacing: "-1.5px",
      lineHeight: "4.5rem",
    },
    h2: {
      fontWeight: "400",
      fontSize: "3rem",
      letterSpacing: "-0.5px",
      lineHeight: "3.25rem",
    },
    h3: {
      fontWeight: "400",
      fontSize: "2rem",
      letterSpacing: "0",
      lineHeight: "2.5rem",
    },
    h4: {
      fontWeight: "400",
      fontSize: "1.5rem",
      letterSpacing: "0.25px",
      lineHeight: "2rem",
    },
    h5: {
      fontWeight: "400",
      fontSize: "1.25rem",
      letterSpacing: "0.15px",
      lineHeight: "1.75rem",
    },
    h5SemiBold: {
      fontWeight: "600",
      fontSize: "20px",
      letterSpacing: "0.15px",
      lineHeight: "28px",
    },
    subtitle1: {
      fontWeight: "400",
      fontSize: "1.125rem",
      letterSpacing: "0.15px",
      lineHeight: "1.5rem",
    },
    subtitle2: {
      fontWeight: "400",
      fontSize: "1rem",
      letterSpacing: "0.15px",
      lineHeight: "1.5rem",
    },
    Subtitle16Bold: {
      fontWeight: "700",
      fontSize: "1rem",
      letterSpacing: "0.15px",
      lineHeight: "1.375rem",
    },
    subtitle16Regular: {
      fontWeight: "400",
      fontSize: "1rem",
      letterSpacing: "0.15px",
      lineHeight: "1.375rem",
    },
    subtitle18SemiBold: {
      fontWeight: "600",
      fontSize: "1.125rem",
      letterSpacing: "0.15px",
      lineHeight: "1.5rem",
    },
    body1: {
      fontWeight: "400",
      fontSize: "1rem",
      letterSpacing: "0.15px",
      lineHeight: "1.5rem",
    },
    body2: {
      fontWeight: "400",
      fontSize: "0.875rem",
      letterSpacing: "0.15px",
      lineHeight: "1.25rem",
    },
    paragraph1: {
      fontWeight: "400",
      fontSize: "1rem",
      letterSpacing: "0.15px",
      lineHeight: "1.75rem",
    },
    paragraph2: {
      fontWeight: "400",
      fontSize: "0.875rem",
      letterSpacing: "0.15px",
      lineHeight: "1.5rem",
    },
    buttonLarge: {
      fontWeight: "600",
      fontSize: "0.875rem",
      letterSpacing: "0.4px",
      lineHeight: "1.5rem",
    },
    buttonSmall: {
      fontWeight: "600",
      fontSize: "0.75rem",
      letterSpacing: "0.46px",
      lineHeight: "1.25rem",
    },
    caption: {
      fontWeight: "400",
      fontSize: "0.75rem",
      letterSpacing: "0.4px",
      lineHeight: "1.125rem",
    },
    overline: {
      fontWeight: "400",
      fontSize: "0.75rem",
      letterSpacing: "1px",
      lineHeight: "1rem",
    },
    body16semibold: {
      fontWeight: "600",
      fontSize: "1rem",
      letterSpacing: "0.15px",
      lineHeight: "1.5rem",
    },
    body16Regular: {
      fontWeight: "400",
      fontSize: "1rem",
      letterSpacing: "0.15px",
      lineHeight: "1.5rem",
    },
    h2bold: {
      fontWeight: "700",
      fontSize: "48px",
      letterSpacing: "-0.5px",
      lineHeight: "56px",
    },
    h5bold: {
      fontWeight: "700",
      fontSize: "20px",
      lineHeight: "28px",
    },
    paragraph16Regular: {
      fontWeight: "400",
      fontSize: "16px",
      letterSpacing: "0.15px",
      lineHeight: "28px",
    },
    paragraph14Regular: {
      fontWeight: "400",
      fontSize: "14px",
      letterSpacing: "0.15px",
      lineHeight: "24px",
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#E3F3FF",
            borderRadius: 4,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          fontSize: "0.875rem",
          fontWeight: "400",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          // border: '1px solid',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: "#E0E0E0",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: "#045FC4",
          },
        },
      },
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
