import * as yup from 'yup';
import regex from '../../constants/regex';

const validationNotEmpty = (label: string) => yup.string().required(`${label} must be filled!`)
const validationNullable = () => yup.string().nullable()

const validationTextField = (
  label: string,
  minimal: number = 5,
  maximal: number = 100
) =>
  yup
    .string()
    .typeError(`${label} must be filled!`)
    .required(`${label} must be filled!`)
    .min(minimal, `${label} minimum ${minimal} characters!`)
    .max(maximal, `${label} maximum ${maximal} characters!`);

const validationTextFieldNoSymbol = (
  label: string,
  minimal: number = 5,
  maximal: number = 100
) =>
  yup
    .string()
    .typeError(`${label} must be filled!`)
    .required(`${label} must be filled!`)
    .min(minimal, `${label} minimum ${minimal} characters!`)
    .max(maximal, `${label} maximum ${maximal} characters!`)
    .test(
      'symbol',
      `${label} only allows for alphabet and numeric!`,
      (val: any) => /^[A-Za-z0-9]*$/.test(val)
    );

const validationTextFieldOnlyLettersExactLength = (
  label: string,
  length: number = 10
) =>
  yup
    .string()
    .typeError(`${label} must be filled!`)
    .required(`${label} must be filled!`)
    .length(length, `${label} must be ${length} characters!`)
    .test('symbol', `${label} only allows for alphabet!`, (val: any) =>
      /^[A-Za-z]*$/.test(val)
    );

const validationTextFieldNoNumbers = (
  label: string,
  minimal: number = 5,
  maximal: number = 100
) =>
  yup
    .string()
    .typeError(`${label} must be filled!`)
    .required(`${label} must be filled!`)
    .min(minimal, `${label} minimum ${minimal} characters!`)
    .max(maximal, `${label} maximum ${maximal} characters!`)
    .test('numbers', `${label} cannot contain number!`, (val: any) =>
      /^[\D]*$/.test(val)
    );

const validationStringSelect = (label: string) =>
  yup.string().nullable().required(`${label} must be selected!`);

const validataionTextEditor = (
  label: string,
  min: number = 0,
  max: number = 2000
) =>
  yup
    .string()
    .required(`${label} must be filled!`)
    .test('min', `${label} Minimum ${min} Characters!`, val => {
      if (val) {
        const textOnly = val.replace(/<[^<>]+>/g, '').length;
        if (textOnly < min) return false;
        return true;
      }
      return false;
    })
    .test('max', `${label} Maximum ${max} Characters!`, val => {
      if (val) {
        const textOnly = val.replace(/<[^<>]+>/g, '').length;
        if (textOnly > max) return false;
        return true;
      }
      return false;
    });

const validationNumberSelect = (label: string) =>
  yup
    .number()
    .typeError(`${label} must be filled!`)
    .required(`${label} must be filled!`)
    .test(
      'len',
      `${label} cannot below 0`,
      val => val !== undefined && val >= 0
    );

const validationPercentNumber = (label: string) =>
  yup
    .number()
    .test(
      'len',
      `${label} must be in percent`,
      val => val !== undefined && val >= 0 && val <= 100
    )
    .typeError(`${label} must be in percent`)
    .required(`${label} must be filled!`);

const validationBooleanSelect = (label: string) =>
  yup.boolean().nullable().required(`${label} must be filled!`);

const validationTextFieldNoMin = (label: string) =>
  yup.string().required(`${label} must be filled!`);

const validationNumberField = (label: string, min = 0) =>
  yup
    .string()
    .test('check if number', `${label} must be number`, (val: any) => {
      const isNumber = /^\d+$/.test(val);
      if (val === '' || val === undefined) {
        return true;
      }
      return isNumber;
    })
    .test('len', `${label} cant be less than ${min}`, (val: any) => {
      if (val === '' || val === undefined) {
        return true;
      }
      return Number(val) >= min;
    })
    .required(`${label} must be filled!`);

const validationNumberFieldWithMax = (label: string, min = 0, max = 1000) =>
  yup
    .number()
    .typeError(`${label} must be filled!`)
    .required(`${label} must be filled!`)
    .min(min, `${label} cant be less than ${min}`)
    .max(max, `${label} cant be more than ${max}`);

const validationCurrencyFieldWithMax = (
  label: string,
  min = 0,
  max = 9999999999999
) =>
  yup
    .number()
    .typeError(`${label} must be filled!`)
    .required(`${label} must be filled!`)
    .min(min, `${label} minimum ${min.toString().length} character!`)
    .max(max, `${label} maximum ${max.toString().length} characters!`);

const validationDateField = (label: string) =>
  yup
    .date()
    .typeError(`${label} must be filled!`)
    .required(`${label} must be filled!`)
    .max(
      new Date(new Date().setDate(new Date().getDate() + 1)),
      `${label} Cannot be in the future!`
    );

const validationObjectSelect = (label: string) =>
  yup
    .object()
    .typeError(`${label} must be filled`)
    .shape({
      value: yup.string().required(`${label} must be selected!`),
    });

const validationEmailField = (
  label: string,
  min: number = 5,
  max: number = 100,
  msg: string = ''
) =>
  yup
    .string()
    .required(`${label} must be filled!`)
    .min(min, `${label} Minimum ${min} Characters!`)
    .max(max, `${label} Maximum ${max} Characters!`)
    .matches(regex.regexEmail, msg || `${label} format is incorrect!`);

const validationTextFieldExactLength = (label: string, length: number) =>
  yup
    .string()
    .required(`${label} must be filled!`)
    .length(length, `${label} must be ${length} characters!`)
    .matches(regex.regexNoSpecialCharacter, 'No Special Character are Allowed');

const validationTextArea = (label: string, maximal: number = 100) =>
  yup.string().max(maximal, `${label} maximum ${maximal} characters!`);

const validationArraySelect = (label: string) =>
  yup
    .array()
    .typeError(`${label} must be selected`)
    .test(
      'length',
      `${label} must be selected!`,
      (value, context) => Array.isArray(value) && value?.length > 0
    );

const validationCurrencyLength = (
  label: string,
  minimal: number = 0,
  maximal: number = 10
) =>
  yup
    .string()
    .test('check if number', `${label} must be number`, (val: any) => {
      const isNumber = /^\d+$/.test(val);
      if (val === '' || val === undefined) {
        return true;
      }
      return isNumber;
    })
    .test('len', `${label} minimum ${minimal} characters!`, (val: any) => {
      if (val === '' || val === undefined) {
        return true;
      }
      return val.length >= minimal;
    })
    .test('len', `${label} maximum ${maximal} characters!`, (val: any) => {
      if (val === '' || val === undefined) {
        return true;
      }
      return val.length <= maximal;
    })
    .required(`${label} must be filled!`);

const validationNumberLength = (
  label: string,
  minimal: number = 0,
  maximal: number = 10
) =>
  yup
    .string()
    .test('check if number', `${label} must be number`, (val: any) => {
      const isNumber = /^[0-9]+$/.test(val);
      if (val === '' || val === undefined) {
        return true;
      }
      return isNumber;
    })
    .test('len', `${label} minimum ${minimal} characters!`, (val: any) => {
      if (val === '' || val === undefined) {
        return true;
      }
      return val.length >= minimal;
    })
    .test('len', `${label} maximum ${maximal} characters!`, (val: any) => {
      if (val === '' || val === undefined) {
        return true;
      }
      return val.length <= maximal;
    })
    .required(`${label} must be filled!`);

export {
  validationNotEmpty,
  validationNullable,
  validationTextField,
  validationNumberSelect,
  validationPercentNumber,
  validationStringSelect,
  validationBooleanSelect,
  validationTextFieldNoMin,
  validationNumberField,
  validationDateField,
  validationObjectSelect,
  validationTextArea,
  validationEmailField,
  validationTextFieldExactLength,
  validationNumberFieldWithMax,
  validationArraySelect,
  validataionTextEditor,
  validationCurrencyFieldWithMax,
  validationNumberLength,
  validationTextFieldNoSymbol,
  validationCurrencyLength,
  validationTextFieldNoNumbers,
  validationTextFieldOnlyLettersExactLength,
};
