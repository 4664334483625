import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import InputForm from '../forms/inputForm';
// import SelectForm from '../forms/selectFormm';
// import { useAppDispatch } from '../../../store';
import {  } from '../forms';
import { useEffect, useState } from 'react';
import useDebouncedEffect from '../../../hooks/useDebouncedEffect';
// import DatePickerForm from '../forms/datePickerForm';
// import dayjs from 'dayjs';

const schema = yup.object({
  title: yup.string().required(),
});

const StructureCaCrlsSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [caName, setServiceName] = useState("");
  // const [endpointUrl, setEndpointUrl] = useState("");
  // const [crlCreatedAt, setCreatedAt] = useState("");
  // const [updatedAt, setUpdatedAt] = useState("");

  const {
    control,
    formState: { errors },
  } = useForm<{
    title: string;
    policy: Array<string>;
    isActive: boolean;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      title: "",
      policy: [],
      isActive: false,
    },
  });

  const handleServiceName = (e: any) => {
    setServiceName(e);
  };

  // const handleEndpointUrl = (e: any) => {
  //   setEndpointUrl(e);
  // };

  // const handleCreatedAt = (e: any) => {
  //   let NewDate: any
  //   if (e !== null) {
  //       NewDate = dayjs(e).format("YYYY-MM-DD");
  //   } else {
  //       NewDate = ''
  //   }
  //   setCreatedAt(NewDate);
  // };

  // const handleUpdatedAt = (e: any) => {
  //   let NewDate: any
  //   if (e !== null) {
  //       NewDate = dayjs(e).format("YYYY-MM-DD");
  //   } else {
  //       NewDate = ''
  //   }
  //   setUpdatedAt(NewDate);
  // };

  useDebouncedEffect(
    () => setSearchFilterDebounced(caName),
    [caName],
    500
  );
  // useDebouncedEffect(
  //   () => setSearchFilterDebounced(endpointUrl),
  //   [endpointUrl],
  //   500
  // );
  // useDebouncedEffect(
  //   () => setSearchFilterDebounced(crlCreatedAt),
  //   [crlCreatedAt],
  //   500
  // );
  // useDebouncedEffect(
  //   () => setSearchFilterDebounced(updatedAt),
  //   [updatedAt],
  //   500
  // );

  useEffect(() => {
    setParams({
      ...params,
      // createdAtStart: crlCreatedAt,
      // updatedAtStart: updatedAt,
      profile_name: caName,
      // endpointUrl: endpointUrl
    });
  }, [searchFilterDebounced]);

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "row",
      gap: "1rem",
      flex: 1,
      mt: "-1rem",
      mb: "0.7rem",
      }}>
      <InputForm
        name="caName"
        disabled={false}
        errors={errors}
        control={control}
        required
        placeholder="CA Name"
        maxLength={100}
        isLoading={false}
        onChange={handleServiceName}
      />
      {/* <DatePickerForm
        name="crlCreatedAt"
        defaultValue={null}
        control={control}
        errors={errors}
        placeholder="Crl Created At"
        disabled={false}
        isLoading={false}
        required
        onChange={handleCreatedAt}
      />
      <DatePickerForm
        name="updatedAt"
        defaultValue={null}
        control={control}
        errors={errors}
        placeholder="Crl Expires"
        disabled={false}
        isLoading={false}
        required
        onChange={handleUpdatedAt}
      /> */}
    </Box>
  );
};

export default StructureCaCrlsSearch;
