import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { UserData } from '../../utils/datajson/datachart';
import BarChart from '../../components/molecules/chart/BarChart';
import 'chart.js/auto';
import { Button, Grid, Paper, Typography } from '@mui/material';
import DashTable from '../../components/organism/dashTable/DashTable';
import BasicSelect from '../../components/atoms/basicSelect/BasicSelect';
import { useAppDispatch } from '../../store';
import { getMenuList } from '../../store/feature/menu';

const Dashboard = () => {
  const dispatch = useAppDispatch()
  // console.log(
  //   UserData.map((data) => data.userGain),
  //   'result'
  // );
  const [userData, setUserData] = useState<any>({
    label: UserData.map((data) => data.year),
    datasets: [
      {
        label: 'Users Gained',
        data: UserData,
        backgroundColor: [
          '#303F9F',
          '#A52885',
          '#F4888B',
          '#F39617',
          '#2EB2A5',
          '#453187',
        ],
      },
    ],
  });
  useEffect(()=> {
    dispatch(getMenuList(''))
  })

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '3rem',
          '& > :not(style)': {
            m: 1,
            // width: 800,
            // height: 400,
          },
        }}
      >
        <Paper elevation={24} sx={{ p: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5bold">User Data</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Device</Typography>
              <Typography variant="h5bold">0</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Certificate</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">About to Expired</Typography>
              <Typography variant="h5bold">0</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Total Device & Certificate
              </Typography>
              <Typography variant="h5bold">0</Typography>
            </Grid>
          </Grid>
        </Paper>

        <Paper elevation={4} sx={{ p: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5bold">Officer Data</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Device</Typography>
              <Typography variant="h5bold">14</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Certificate</Typography>
              <Typography variant="h5bold">24</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">About to Expired</Typography>
              <Typography variant="h5bold">0</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Total Device & Certificate
              </Typography>
              <Typography variant="h5bold">38</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: '3rem',
          '& > :not(style)': {
            m: 1,
          },
        }}
      >
        <Paper elevation={24} sx={{ p: 4, flex: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5bold">User Data</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Device</Typography>
              <Typography variant="h5bold">0</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Certificate</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">About to Expired</Typography>
              <Typography variant="h5bold">0</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Total Device & Certificate
              </Typography>
              <Typography variant="h5bold">0</Typography>
            </Grid>
          </Grid>
        </Paper>

        <Paper elevation={4} sx={{ p: 4, flex: 2 }}>
          <BarChart />
        </Paper>
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: '3rem',
          '& > :not(style)': {
            m: 1,
            // width: 800,
            // height: 400,
          },
        }}
      >
        <Paper elevation={24} sx={{ p: 4, flex: 1 }}>
          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={4}>
              <Typography variant="h5bold">
                Certificate Per Device Table
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <BasicSelect />
                </Grid>
                <Grid item xs={4}>
                  <Button variant="contained">Detail</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <DashTable />
        </Paper>
        <Paper elevation={24} sx={{ p: 4, flex: 1 }}>
          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={4}>
              <Typography variant="h5bold">High Impact Events</Typography>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <BasicSelect />
                </Grid>
                <Grid item xs={4}>
                  <Button variant="contained">Detail</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <DashTable />
        </Paper>
      </Box>
    </Box>
  );
};

export default Dashboard;
