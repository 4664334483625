import React from 'react';
import {
  FormControl,
  FormHelperText,
  SxProps,
  Theme,
  useTheme,
  Skeleton,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { CalendarPickerView } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import FormLabel from '../../../atoms/formLabel/index';
import { StyledInput } from './element';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

interface Iprops {
  sxFC?: SxProps<Theme>;
  sxFL?: SxProps<Theme>;
  name: string;
  label?: string;
  errors?: any;
  control?: any;
  defaultValue: string | null;
  disabled?: boolean;
  onChange?: Function;
  required?: boolean;
  placeholder?: string;
  views?: Array<CalendarPickerView>;
  inputFormat?: string;
  isLoading?: boolean;
  specificName?: string;
  minDate?:number;
}

export default function DatePickerForm(props: Iprops) {
  const {
    sxFC,
    sxFL,
    name,
    label,
    control,
    defaultValue,
    errors,
    disabled,
    onChange: onChangeValue,
    required = false,
    placeholder = '',
    views = ['day', 'month', 'year'],
    inputFormat = 'dd/MM/yyyy',
    isLoading = false,
    specificName = '',
    minDate = 1515575136000
  } = props;
  const { palette } = useTheme();
  return (
    <FormControl
      sx={{
        flex: 1,
        display: 'flex',
        mb: '0.5rem', mt: '1rem',
        ...sxFC,
      }}
      variant="standard"
    >
      {label?.length !== 0 && <FormLabel htmlFor={name} sx={{ color: palette.neutral.dark, ...sxFL }}>
        {label}
        {!required && !disabled && (
          <span
            style={{
              marginLeft: '4px',
              color: palette.neutral.light,
              fontWeight: 400,
              fontSize: '1rem',
              lineHeight: '1.5rem',
              fontStyle: 'italic',
            }}
          >
            (optional)
          </span>
        )}
      </FormLabel>}
      {!isLoading && (
        <Controller
          defaultValue={defaultValue}
          control={control}
          name={name}
          render={({ field }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                // views={views}
                {...field}
                inputFormat={inputFormat}
                value={field.value || null}
                disabled={disabled}
                minDate={minDate}
                onChange={(newValue: any) => {
                  field.onChange(newValue);
                  // onChange(newValue);
                  onChangeValue?.(newValue);
                }}
                // components={{
                //   OpenPickerIcon: <CalendarMonthIcon />,
                // }}
                renderInput={(params: any) => {
                  const newParams = {
                    ...params,
                    inputProps: {
                      ...params.inputProps,
                      placeholder: placeholder || 'dd/mm/yyyy',
                    },
                  };

                  return (
                    <StyledInput
                      id={name}
                      sx={[
                        { fontSize: '2.875rem' },
                        errors[name] !== undefined && {
                          '& fieldset': { borderColor: palette.error.main },
                        },
                      ]}
                      helperText={null}
                      size="small"
                      {...newParams}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          )}
        />
      )}
      {isLoading && (
        <Skeleton
          style={{
            height: '40px',
            borderRadius: '4px',
          }}
        />
      )}
      <FormHelperText sx={{ fontSize: '0.75rem', color: palette.error.main }}>
        {errors[name] && errors[name].message}
        {errors[specificName]?.message && errors[specificName].message}
      </FormHelperText>
    </FormControl>
  );
}
