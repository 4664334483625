import {
  Box,
  Button,
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SelectFormm } from "../../components/molecules/forms";
import StrucCaCrlsTableDetails from "../../components/molecules/structureCaCrls/StrucCaCrlsTableDetails ";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import {
  getCertCaCrlsDetails,
  removeEventFlag,
  revokeCertificateCaCrls,
} from "../../store/feature/structureCaCrls";
import { useSearchParams } from "react-router-dom";
import { toPascalCase } from "../../utils/pascalCase";

// icon
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import StructureCaCrlsInformationSkeleton from "../../components/template/skeleton/StructureCaCrlsInformationSkeleton";
import { initialAccess } from "../../store/feature/user";

const StructureCaCrlsDetailInformation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [param] = useSearchParams()
  const idCert = param.get('cert');
  const [alignment, setAlignment] = useState("subordinateCertificate");
  const [revokeReason, setRevokeReason] = useState(""); // for some reason on revoke
  const [revokeError, setRevokeError] = useState(false); // for message error when revoke
  const { caCrlsDetails, revokeMsg, isLoadingCertCAandCRL, isLoadingEvent, eventFlag } = useAppSelector(
    (state: RootState) => state.cerCA
  );
  const [ access, setAccess ] = useState(initialAccess)
  const { accessCrud } = useAppSelector((store: RootState) => store.user);
  
  const certIssuer = caCrlsDetails.filter(
    (item: any) => item.label === "Certificate Chain Serial Number"
  );
  console.log('caCrlsDetails ===> ', caCrlsDetails)
  const certSerialNumber = caCrlsDetails.filter(
    (item: any) => item.label === "Certificate Serial Number"
  );
  const certStatus = caCrlsDetails.filter(
    (item: any) => item.label === "Certificate Status"
  );

  const revReasonArr = caCrlsDetails.filter(
    (item: any) => item.label === "Certificate Revoke Reason"
  );

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    // setAlignment(newAlignment);
    if (newAlignment === null) {
      return;
    }
    setAlignment(newAlignment)
    dispatch(getCertCaCrlsDetails(newAlignment));
  };
  
  useEffect(() => {
    if(eventFlag) {
      dispatch(getCertCaCrlsDetails(idCert));
      dispatch(removeEventFlag())
    }
  }, [eventFlag]);

  useEffect(() => {
    const obj = accessCrud.find((item: any) => item.uid === "/ca-structure-and-crls")
    setAccess(obj != undefined ? obj : initialAccess)
  }, [accessCrud]);

  const {
    control,
    reset,
    formState: { errors },
  } = useForm<{
    revReason: string;
  }>({
    defaultValues: {
      revReason: "",
    },
  });

  useEffect(() => {
    // console.log('hahaha ===> ', revReasonArr[0]?.value.toUpperCase().split(' ').join('_')) 
    if(!caCrlsDetails.find((item: any)=> item.value === 'active')){
      reset({
        revReason: revReasonArr[0]?.value.toUpperCase().split(' ').join('_'),
      });
    } else {
      reset({
        revReason: '',
      });
    }
  }, [caCrlsDetails, eventFlag]);

  const reason = alignment === "issuingCertificate";

  const optStatusSelect = [
    {
      label: "Unspecified",
      value: "UNSPECIFIED",
    },
    {
      label: "Key Compromise",
      value: "KEY_COMPROMISE",
    },
    {
      label: "CA Compromise",
      value: "CA_COMPROMISE",
    },
    {
      label: "Affiliation Changed",
      value: "AFFLIATION_CHANGED",
    },
    {
      label: "Superseded",
      value: "SUPERSEDED",
    },
    {
      label: "Cessation Of Operation",
      value: "CESSATION_OF_OPERATION",
    },
    {
      label: "Certificate Hold",
      value: "CERTIFICATE_HOLD",
    },
    {
      label: "Remove From CRL",
      value: "REMOVE_FROM_CRL",
    },
    {
      label: "Privilege Withdrawn",
      value: "PRIVILEGE_WITHDRAWN",
    },
    {
      label: "AA Compromise",
      value: "AA_COMPROMISE",
    },
  ];

  const onChangeRevoke = (e: any) => {
    setRevokeReason(e.value);
    setRevokeError(false);
  };
  const handleRevoke = (e: any) => {
    const issuerNumbCert = certSerialNumber[0]?.value;
    if (revokeReason.length === 0) {
      setRevokeError(true);
      return;
    }
    setRevokeError(false);
    const revCertReq = {
      certNumber: issuerNumbCert,
      revokeReason: revokeReason,
    };
    Swal.fire({
      title: 'Revoke Confirmation',
      text: "Are you sure? You won't be able to revert this!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(revokeCertificateCaCrls(revCertReq));
      }
    });
  };

  const editFgroup = true;
  const subOrdinate =certIssuer[0]?.value !== null ? certIssuer[0]?.value[0] === certSerialNumber[0]?.value : false
  return (
    <StructureCaCrlsInformationSkeleton isLoading={isLoadingCertCAandCRL} isLoadingEvent={isLoadingEvent}>
      <Box paddingY="25px">
        <Grid container spacing={2}>
        {certIssuer[0]?.value !== null && (
            <Grid item xs={12} justifyContent="center" display={"flex"}>
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                defaultValue="subordinateCertificate"
                exclusive
                onChange={handleChange}
                aria-label="Platform"
                fullWidth
                sx={{ width: "90%" }}
              >
                {!subOrdinate && <ToggleButton
                  value="subordinateCertificate"
                  sx={{ borderRadius: "25px" }}
                  color="standard"
                >
                  <Typography variant="h5">Subordinate Certificate </Typography>
                </ToggleButton>}
                {certIssuer[0]?.value.map((item: any, index: number) => (
                  <ToggleButton
                    value={item}
                    sx={{ borderRadius: "25px" }}
                    key={item}
                  >
                    <Typography variant="h5">
                      Issuing Certificate {index + 1} 
                    </Typography>
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            paddingBottom={2}
            display="flex"
            flexDirection="row"
            fontFamily="Open Sans"
          >
            <IconButton
              sx={{ background: "#FFF", ":hover": { background: "#FFF" } }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIosIcon />
            </IconButton>
            <Typography variant="h3">View Certificate</Typography>
          </Grid>
          {!reason && access.update && (
            <>
              <Grid
                item
                xs={12}
                display="flex"
                gap="20px"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="body1">Revoke reason</Typography>
                <Box sx={{ width: "300px" }}>
                  <SelectFormm
                    name="revReason"
                    defaultValue={1}
                    options={optStatusSelect}
                    placeholder="None"
                    disabled={revReasonArr[0]?.value !== 'null'}
                    isLoading={false}
                    control={control}
                    errors={errors}
                    onChangeForm={onChangeRevoke}
                    required
                    sxFC={{ marginTop: "7px" }}
                  />
                </Box>
                <Button
                  variant="contained"
                  disabled={certStatus[0]?.value === "revoked" || revReasonArr[0]?.value !== 'null'}
                  onClick={handleRevoke}
                >
                  Revoke
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignContent="center"
                mb="10px"
              >
                {revokeError && (
                  <span style={{ color: "red" }}>please select reason</span>
                )}
              </Grid>
            </>
          )}
          <Grid
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <StrucCaCrlsTableDetails />
          </Grid>
        </Grid>
      </Box>
    </StructureCaCrlsInformationSkeleton>
  );
};

export default StructureCaCrlsDetailInformation;
