import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../service/instance";
import { store } from '../index';
import Swal from "sweetalert2";

  export interface IForm {
    isLoadingForm: boolean;
    isLoadingEvent: boolean;
    eventFlag: boolean;
    formData : any;
    rowsData: any;
    totalRecForm: number;
    entryId: number;
    msgDelete: string;
    updateMsg: string;
    formTypeName: string;
  };

  const initialState: IForm = {
    isLoadingForm: false,
    isLoadingEvent: false,
    eventFlag: false,
    formData: [],
    rowsData: [],
    totalRecForm: 0,
    entryId: 0,
    msgDelete: '',
    updateMsg: '',
    formTypeName: '',
  };

  export const getHeaderTable = createAsyncThunk<any, any, { rejectValue: any }>(
    "form/header-list",
    async ({id, page_access_uid}, { rejectWithValue }) => {
      try {
        const resp:any = await instance.get(`/params/${id}`, { 
          headers: {
            'page-access-uid': page_access_uid
            }
         });
        let formHeader = resp.data.data.form_params.slice(0, 4)
        let paramName =  resp.data.data.form_type_name
        return {formHeader, paramName};
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );

  export const getBodyTable = createAsyncThunk<any, any, { rejectValue: any }>(
    "form/body-list",
    async ({id, page_access_uid, params}, { rejectWithValue }) => {
      try {
        const resp = await instance.get(`/form/entries/${id}`, { 
          params,
          headers: {
            'page-access-uid': page_access_uid
          }
         });
        return resp.data.data;
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );

  export const createFormValue = createAsyncThunk<any, any, { rejectValue: any }>(
    "form/create-value",
    async (data, { rejectWithValue, dispatch }) => {
      try {
        const { page_access_uid } = data
        const resp: any = await instance.post('/form/value', data, {
          headers: {
            'page-access-uid': page_access_uid
          }
        });
        dispatch(removeEntryId())
        dispatch(removeUpdateMsg())
        return resp.data.data;
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );

  export const createFormValueMulti = createAsyncThunk<any, any, { rejectValue: any }>(
    "form/create-value-multi",
    async (data, { rejectWithValue, dispatch }) => {
      try {
        const { optVal, isCreate, page_access_uid } = data
        const resp:any = await instance.post('/form/value/multiple', optVal, {
          headers: {
            'page-access-uid': page_access_uid
          }
        });
        dispatch(removeEntryId())
        dispatch(removeUpdateMsg())
        const payload = resp.data
        return { payload, isCreate };
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );

  export const updateFormValueEntry = createAsyncThunk<any, any, { rejectValue: any }>(
    "form/update-value-entry",
    async (data, { rejectWithValue }) => {
      try {
        const { entryId, params, page_access_uid } = data
        const resp: any = await instance.put(`/entry/${entryId}`, params, {
          headers: {
            'page-access-uid': page_access_uid
          }
        });
        const status = resp.data.data.status
        return { status, entryId };
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );

  export const deleteFormEntry = createAsyncThunk<any, any, { rejectValue: any }>(
    "form/delete-body-list",
    async ({ id, page_access_uid }, { rejectWithValue }) => {
      try {
        const resp = await instance.delete(`/entry/${id}`, {
          headers: {
            'page-access-uid': page_access_uid
          }
        });
        return resp.data.data;
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );

  export const updateButtonEntry = createAsyncThunk<any, any, { rejectValue: any }>(
    "form/entry-button",
    async (data, { rejectWithValue }) => {
      try {
        const { entryId, params, page_access_uid } = data
        const resp = await instance.put(`/entry-button/${entryId}`, params, {
          headers: {
            'page-access-uid': page_access_uid
          }
        });
        return resp;
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );

  export const formSlice = createSlice({
    name: "form",
    initialState,
    reducers: {
      removeEntryId: (state)=> {
        state.entryId = 0
      }, 
      removeUpdateMsg: (state)=> {
        state.updateMsg = ''
      },
      setEventFlag: (state) => {
        state.eventFlag = true;
      },
      removeEventFlag: (state) => {
        state.eventFlag = false;
      }
    },
    extraReducers(builder) {
      builder

      // REGION: GET HEADER TABLE
      .addCase(getHeaderTable.pending, (state)=> {
        state.isLoadingForm = true;
      })
      .addCase(getHeaderTable.fulfilled, (state, action)=> {
        state.isLoadingForm = false;
        state.formData = action.payload.formHeader
        state.formTypeName = action.payload.paramName
      })
      .addCase(getHeaderTable.rejected, (state)=> {
        state.isLoadingForm = false;
        state.formData = []
        // state.formTypeName = ''
      })

      // REGION: GET BODY TABLE
      .addCase(getBodyTable.pending, (state)=> {
        state.isLoadingForm = true;
      })
      .addCase(getBodyTable.fulfilled, (state, action)=> {
        state.isLoadingForm = false;
        state.rowsData = action.payload.data
        state.totalRecForm =  action.payload.total_records
      })
      .addCase(getBodyTable.rejected, (state)=> {
        state.isLoadingForm = false;
        state.rowsData = []
        state.totalRecForm =  0
      })

      // REGION: CREATE FORM
      // DO NOT NEED EVENT-FLAG CAUSE HANDLED BY CREATE FORM MULTI
      .addCase(createFormValue.pending, (state)=> {
        state.isLoadingEvent = true
        state.eventFlag = false
      })
      .addCase(createFormValue.fulfilled, (state, action)=> {
        state.isLoadingEvent = false
        state.entryId = action.payload.form_entry_uid
      })
      .addCase(createFormValue.rejected, (state, action)=> {
        state.isLoadingEvent = false
        let message = "Can not create form, Please try again"
        let errDetail = ""
        try {
          errDetail = action.payload.response.data.errors[0].detail
        } catch(e) { }
        Swal.fire({
          icon: "error",
          title:`Error create ${state.formTypeName.toLowerCase()}`,
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: '#bfd2e0',
          text: errDetail != "" ? errDetail : "Please click “OK” button to continue",
          didOpen: () => Swal.getConfirmButton()?.focus()
        })
        .then((_) => {
          store.dispatch(removeEventFlag())
        });
      })
      
      // REGION: CREATE FORM MULTI
      .addCase(createFormValueMulti.pending, (state)=> {
        state.isLoadingEvent = true
        state.eventFlag = false
      })
      .addCase(createFormValueMulti.fulfilled, (state, action)=> {
        state.isLoadingEvent = false
        const { isCreate } = action.payload
        if(isCreate) {
          Swal.fire({
            icon: "success",
            title:`Sucessfully create ${state.formTypeName.toLowerCase()}`,
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: '#bfd2e0',
            text: "Please click “OK” button to continue",
            didOpen: () => Swal.getConfirmButton()?.focus()
          }).then((_) => {
            store.dispatch(setEventFlag())
          });
        }
      })
      .addCase(createFormValueMulti.rejected, (state, action)=> {
        state.isLoadingEvent = false
        let message = "Can not create form multi, Please try again"
        const { payload, isCreate } = action.payload
        let errDetail = ""
        try {
          errDetail = payload.response.data.errors[0].detail
        } catch(e) {}
        if(isCreate) {
          Swal.fire({
            icon: "error",
            title:`Error create ${state.formTypeName.toLowerCase()}`,
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: '#bfd2e0',
            text: errDetail != "" ? errDetail : "Please click “OK” button to continue",
            didOpen: () => Swal.getConfirmButton()?.focus()
          })
          .then((_) => {
            store.dispatch(removeEventFlag())
          });
        }
      })

      // REGION: UPDATE FORM ENTRY
      .addCase(updateFormValueEntry.pending, (state)=> {
        state.isLoadingEvent = true
        state.eventFlag = false
      })
      .addCase(updateFormValueEntry.fulfilled, (state, action)=> {
        const { status, entryId } = action.payload
        state.isLoadingEvent = false
        state.entryId = entryId
        const statMsg = status
        const message = statMsg != "" ? statMsg : "Your form has been updated"
        Swal.fire({
          icon: "success",
          title:`Sucessfully update ${state.formTypeName.toLowerCase()}`,
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: '#bfd2e0',
          text: "Please click “OK” button to continue",
          didOpen: () => Swal.getConfirmButton()?.focus()
        }).then((_) => {
          store.dispatch(setEventFlag())
        });
      })
      .addCase(updateFormValueEntry.rejected, (state, action)=> {
        state.isLoadingEvent = false
        let message = "Can not update form, Please try again"
        let errDetail = ""
        try {
          errDetail = action.payload.response.data.errors[0].detail
        } catch(e) {}
        Swal.fire({
          icon: "error",
          title:`Error update ${state.formTypeName.toLowerCase()}`,
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: '#bfd2e0',
          text: errDetail != "" ? errDetail : "Please click “OK” button to continue",
          didOpen: () => Swal.getConfirmButton()?.focus()
        }).then((_) => {
          store.dispatch(removeEventFlag())
        });
      })

      // REGION: DELETE FORM ENTRY
      .addCase(deleteFormEntry.pending, (state)=> {
        state.isLoadingEvent = true
        state.eventFlag = false
      })
      .addCase(deleteFormEntry.fulfilled, (state, action)=> {
        state.isLoadingEvent = false
        const statMsg = action.payload.status
        const message = statMsg != "" ? statMsg : "Your form has been deleted"
        Swal.fire({
          icon: "success",
          title:`Sucessfully delete ${state.formTypeName.toLowerCase()}`,
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: '#bfd2e0',
          text: "Please click “OK” button to continue",
          didOpen: () => Swal.getConfirmButton()?.focus()
        }).then((_) => {
          store.dispatch(setEventFlag())
        });
      })
      .addCase(deleteFormEntry.rejected, (state, action)=> {
        state.isLoadingEvent = false
        let message = "Can not delete form, Please try again"
        let errDetail = ""
        try {
          errDetail = action.payload.response.data.errors[0].detail
        } catch(e) {}
        Swal.fire({
          icon: "error",
          title:`Error delete ${state.formTypeName.toLowerCase()}`,
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: '#bfd2e0',
          text: errDetail != "" ? errDetail : "Please click “OK” button to continue",
          didOpen: () => Swal.getConfirmButton()?.focus()
        }).then((_) => {
          store.dispatch(removeEventFlag())
        });
      })

      // REGION: UPDATE BUTTON ENTRY
      .addCase(updateButtonEntry.pending, (state)=> {
        state.isLoadingEvent = true
      })
      .addCase(updateButtonEntry.fulfilled, (state)=> {
        state.isLoadingEvent = false
      })
      .addCase(updateButtonEntry.rejected, (state)=> {
        state.isLoadingEvent = false
      })

    },
  });

  export const { removeEntryId, removeUpdateMsg, setEventFlag, removeEventFlag } = formSlice.actions;

  export default formSlice.reducer;