import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import images from '../../assets/pages/not-found.jpg';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img height="600px" src={images} alt="not found logo" />
      <Typography variant="h3" component="h1">
        404. That’s an error.
      </Typography>
      <Typography variant="h6" component="h2" sx={{ color: '#737373' }}>
        There was an error, please try again later
      </Typography>

      <Button
        variant="contained"
        color="primary"
        sx={{ m: 2 }}
        onClick={() => navigate('/')}
      >
        Back to Home
      </Button>
    </Box>
  );
};

export default NotFound;
