import { Box } from '@mui/material';
import { useAppSelector, useAppDispatch, RootState } from '../../store';
import { useEffect } from 'react';
import { getCerProf } from '../../store/feature/cerProf';
import { SelectFormm } from '../../components/molecules/forms';
import DynamicForm from '../../components/molecules/forms/dynamicForm/DynamicForm';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const ProfileField = () => {
  const dispatch = useAppDispatch();
  const { formParams } = useAppSelector((state: RootState) => state.cer);

  // useEffect(() => {
  //   console.log('kepanggil dari page prof');
  //   dispatch(getCerProf(''));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const schema = yup.object({
    name: yup.string().required(),
  });

  const {
    control,
    watch,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      Available_Key_Algorithm: [],
    },
  });

  return (
    <Box>
      {formParams.map((e: any) => (
        <>
          {e.form_param_name === 'Available_Key_Algorithm' && (
            <SelectFormm
              name={e.form_param_name}
              label={e.form_param_title}
              defaultValue={1}
              options={e.form_param_option_values}
              placeholder="Please Select"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              isMulti
            />
          )}
          {e.form_param_name === 'Available_ECDSA_curves' && (
            <SelectFormm
              name={e.form_param_name}
              label={e.form_param_title}
              defaultValue={1}
              options={e.form_param_option_values?.filter(
                (item: any) =>
                  watch('Available_Key_Algorithm')?.findIndex(
                    (o: any) => item.parent_val === o.value
                  ) !== -1
              )}
              placeholder="Please Select"
              disabled={watch('Available_Key_Algorithm')?.length === 0}
              isLoading={false}
              control={control}
              errors={errors}
              isMulti
            />
          )}
          {e.form_param_name === 'Available_Bit_Lengths' && (
            <SelectFormm
              name={e.form_param_name}
              label={e.form_param_title}
              defaultValue={1}
              options={e.form_param_option_values?.filter(
                (item: any) =>
                  watch('Available_Key_Algorithm')?.findIndex(
                    (o: any) => item.parent_val === o.value
                  ) !== -1
              )}
              placeholder="Please Select"
              disabled={watch('Available_Key_Algorithm')?.length === 0}
              isLoading={false}
              control={control}
              errors={errors}
              isMulti
            />
          )}

          {/* {[
            'Available_Bit_Lengths',
            'Available_Key_Algorithm',
            'Available_ECDSA_curves',
          ].indexOf(e.form_param_name) === -1 && (
            <DynamicForm
              type={e.form_param_field_type}
              name={e.form_param_name}
              label={e.form_param_title}
              labelValue={''}
              defaultValue={undefined}
              key={e.form_param_id}
              options={e.form_param_option_values}
            />
          )} */}
        </>
      ))}
    </Box>
  );
};

export default ProfileField;
