import { Box, Button, Chip, Grid, Link, Stack, Typography } from "@mui/material";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
// import {
//   deleteFormGroup,
//   getFormGroup,
// } from '../../../store/feature/formGroup';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import "./FormGroupDataGrid.css";
import {
  downloadCrl,
  generateCrl,
  getCertCaCrls,
  getCertCaCrlsDetails,
  getCertCaCrlsDownloadPem,
  removeEventFlag,
  renewCertificate,
} from "../../../store/feature/structureCaCrls";
import PaginationControlNonD from "../pagination/PaginationNonD";
import { initialAccess } from "../../../store/feature/user";

const StructureCaCrlsTable = (
  {
    theParams,
    setParams,
    defaultValue,
    setDefaultValue,
  }: any
  ) => {

  // REGION: INITIALIZE
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { certCaCrlsList, totalCertCaCrls, eventFlag } = useAppSelector((state: RootState) => state.cerCA);
  const [ access, setAccess ] = useState(initialAccess)
  const { accessCrud } = useAppSelector((store: RootState) => store.user);
  
  // REGION: GET CA FOR PAGINATION
  useEffect(() => {
    dispatch(getCertCaCrls(theParams));
  }, [theParams]);

  // REGION: GET CA CRLS
  useEffect(() => {
    dispatch(getCertCaCrls({ page: 1, pagesize: 10 }));
  }, []);
    
  // REGION: DISPATCH EVENT FLAG
  useEffect(() => {
    if(eventFlag) {
      dispatch(getCertCaCrls(theParams));
      dispatch(removeEventFlag())
    }
  }, [eventFlag]);

  // REGION: DISPATCH ACCESS CRUD
  useEffect(() => {
    const obj = accessCrud.find((item: any) => item.uid === "/ca-structure-and-crls")
    setAccess(obj != undefined ? obj : initialAccess)
  }, [accessCrud]);
  

  // REGION: SUBSTRACT SUBJECT NAME
  const substractSubjectName = (subjectName : String, subject: String) => {
    const regex = new RegExp(`${subject}=([^,]*)`);
    const match = subjectName.match(regex);
    const isMatched = match ? match[1] : null;
    return isMatched
  }

  // REGION: RENDER BUTTON
  const customCellRender = (params: GridCellParams) => {

    // HANDLE: VIEW CERTIFICATE
    const onViewCertificate = (e: any) => {
      e.stopPropagation();
      navigate(`/ca-structure-and-crls/certificate?cert=${params.row.crtSerialNumb}`);
    };

    // HANDLE: GENERATE CRL
    const onGenerateCrl = (e: any) => {
      e.stopPropagation();
      Swal.fire({
        title: "Generate Confirmation",
        text: `Are you sure? You will be generating "${params.row.caName}" CRL`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(generateCrl(params.row.uid))
        }
      });
    };

    // HANDLE: VIEW CA INFORMATION
    const onViewCAInformation = (e: any) => {
      e.stopPropagation();
      navigate(`/ca-structure-and-crls/information/${params.row.uid}`);
    };

    // HANDLE: RENEW CERTIFICATE
    const onRenewCertificate = (e: any) => {
      e.stopPropagation();
      Swal.fire({
        title: "Renew Confirmation",
        text: `Are you sure? Certificate will be renewed after you confirm this message`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(renewCertificate(params.row.crtSerialNumb))
        }
      });
    };

    // HANDLE: DOWNLOAD CRL
    const onDownloadCrl = (e: any) => {
      e.stopPropagation();
      Swal.fire({
        title: "Download Confirmation",
        text: `Are you sure? You will be downloading "${params.row.caName}" CRL`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          const subjectCA = substractSubjectName(params.row.subjectName, "cn")
          dispatch(downloadCrl(subjectCA))
        }
      });
    };

    const onDownloadCertificate = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      console.log("params from click ===> ", params.row.crtSerialNumb);
      dispatch(
        getCertCaCrlsDownloadPem({
          certificateSerialNumber: params.row.crtSerialNumb,
          certificateFormat: "PEM",
          name: params.row.caName,
        })
      );
    };

    return (
      <>
        {
          access.read || access.create
          ? <Grid container spacing={2} mt="20px" mb="20px">
          { access.read &&
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="inherit"
                onClick={onViewCertificate}
                sx={{ width: "100%", height:'60px' }}>
                View Certificate
              </Button>
            </Grid>
          }
          { access.create &&
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="inherit"
                onClick={onGenerateCrl}
                sx={{ width: "100%", height:'60px' }}>
                Generate CRL
              </Button>
            </Grid>
          }
          { access.read &&
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="inherit"
                onClick={onViewCAInformation}
                sx={{ width: "100%", height:'60px' }}>
                View CA Information
              </Button>
            </Grid>
          }
          { access.create &&
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="inherit"
                onClick={onRenewCertificate}
                sx={{ width: "100%", height:'60px' }}>
                Renew Certificate
              </Button>
            </Grid>
          }
          { access.read &&
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="inherit"
                onClick={onDownloadCrl}
                sx={{ width: "100%", height:'60px' }}>
                Download CRL
              </Button>
            </Grid>
          }
          <Grid item xs={4}>
          <Button
                variant="contained"
                color="inherit"
                onClick={onDownloadCertificate}
                sx={{ width: "100%", height:'60px' }}>
                Download Certificate
              </Button>
          </Grid>
        </Grid>
        : <Typography variant='body2'> No Action Available </Typography>
        }
      </>
    );
  };

  // REGION: COLUMN
  const columns: GridColDef[] = [
    {
      field: "caName",
      headerName: "CA Name",
      flex: 1,
      minWidth: 250,
      headerClassName: "centered",
    },
    {
      field: "detailsOfCertificateChain",
      headerName: "Details Of Certificate Chain",
      flex: 1,
      minWidth: 350,
      renderCell: (params: GridCellParams) => {
        const arr = params.row.detailsOfCertificateChain;
        const Item = () => {
          return arr?.map((item: any) => (
            <>
              <Grid item xs={12}>
                {item}
              </Grid>
            </>
          ));
        };
        return (
          <Grid container>
            <Item />
          </Grid>
        );
      },
    },
    // {
    //   field: "downloadCertificate",
    //   headerName: "Download Certificate",
    //   flex: 1,
    //   minWidth: 250,
    //   sortable: false,
    //   renderCell: (params: GridCellParams) => {
    //     const handleClick = (e: any) => {
    //       e.stopPropagation(); // don't select this row after clicking
    //       console.log("params from click ===> ", params.row.crtSerialNumb);
    //       dispatch(
    //         getCertCaCrlsDownloadPem({
    //           certificateSerialNumber: params.row.crtSerialNumb,
    //           certificateFormat: "PEM",
    //           name: params.row.caName,
    //         })
    //       );
    //     };
    //     return (
    //       <>
    //         { access.read
    //           ? <Link href="#" onClick={handleClick} color="#4300ff">
    //             Download file
    //           </Link>
    //           : <Typography variant='body2'> No Download Available </Typography>
    //         }
    //       </>
    //     );
    //   },
    // },
    {
      field: "createAt",
      headerName: "Created At (latest CRL)",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "expires",
      headerName: "Expires (latest CRL)",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 2.5,
      minWidth: 400,
      sortable: false,
      headerAlign: "center",
      renderCell: customCellRender,
      cellClassName: "super-app-theme--header",
    },
  ];

  // REGION: ROWS
  const rows = certCaCrlsList.map((row: any) => ({
    id: row.entry_id,
    uid: row.entry_uid,
    caName: row.issuer_profile_name,
    downloadCertificate: "Root CA: CN=Akbar.rootca",
    createAt: row.crl_details.created_date,
    expires: row.crl_details.expire_date,
    detailsOfCertificateChain: row.cert_chain_details,
    crtSerialNumb: row.crt_serial_number,
    subjectName: row.subject_name
  }));

  return (
    <>
      <Box
        sx={{
          height: "650px",
          "& .super-app-theme--header": {
            borderLeft: "1px solid #ddd;",
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          // autoHeight
          getRowHeight={() => "auto"}
          // rowHeight={950}
        />
      </Box>
      <PaginationControlNonD
        params={theParams}
        setParams={setParams}
        total={totalCertCaCrls}
      />
    </>
  );
};

export default StructureCaCrlsTable;