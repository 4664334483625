import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { SelectFormm } from "../forms";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { setPageKey } from "../../../store/feature/certificateProfileSlice";
import { useTheme } from '@mui/material/styles';

const schema = yup.object({
  title: yup.string().required(),
});

const SearchUserCertificateSearch = ({ params, setParams }: any) => {
  const {palette} = useTheme()
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [subjectName, setSubjectName] = useState("");
  const [creationDateFrom, setCreationDateFrom] = useState("");
  const [creationDateTo, setCreationDateTo] = useState("");
  const [issuerSubjectName, setIssuerSubjectName] = useState("");
  const [status, setStatus] = useState("");
  const dispatch = useAppDispatch();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    serialNumber: string;
    subjectName: string;
    creationDateFrom: string;
    creationDateTo: string;
    issuerSubjectName: string;
    status: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      serialNumber: "",
      subjectName: "",
      creationDateFrom: "",
      creationDateTo: "",
      issuerSubjectName: "",
      status: "",
    },
  });

  const handleSerialNumber = (e: any) => { setSerialNumber(e); };
  const handleSubjectName = (e: any) => { setSubjectName(e); };
  const handleIssuerSubjectName = (e: any) => { setIssuerSubjectName(e); };
  const handleStatus = (e: any) => { setStatus(e.value); };
  const handleCreationDateFrom = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if(dayjs(e).isAfter(dayjs(creationDateTo))) {
        console.log('hahaha')
        setAllDate(NewDate);
      } else {
        setCreationDateFrom(NewDate);
      }
    } else {
      NewDate = "";
      setAllDate(NewDate);
    }
  };
  const setAllDate = (date: string) => {
    reset({
      serialNumber: serialNumber,
      subjectName: subjectName,
      creationDateFrom: date,
      creationDateTo: date,
      issuerSubjectName: issuerSubjectName,
      status: status
    });
    setCreationDateFrom(date);
    setCreationDateTo(date);
  }
  const handleCreationDateTo = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if(conditionCheck()) setCreationDateTo(NewDate);
  };
  const conditionCheck = () => {
    if(creationDateFrom === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: palette.primary.main,
        text: "Please select Creation Date From"
      });
      reset({
        serialNumber: serialNumber,
        subjectName: subjectName,
        creationDateFrom: "",
        creationDateTo: "",
        issuerSubjectName: issuerSubjectName,
        status: status
      });
      console.log('creation date to booor ===> ')
      setCreationDateTo("");
      return false
    } else {
      return true
    }
  }

  useDebouncedEffect(() => setSearchFilterDebounced(serialNumber), [serialNumber], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(subjectName), [subjectName], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(creationDateFrom), [creationDateFrom], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(creationDateTo), [creationDateTo], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(issuerSubjectName), [issuerSubjectName], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(status), [status], 500);

  const optStatusSelect = [
    {
      label: "Active",
      value: "ACTIVE",
    },
    {
      label: "Revoked",
      value: "REVOKED",
    },
  ];

  useEffect(() => {
    setParams({
      ...params,
      serialNumber: serialNumber,
      subjectName: subjectName,
      creationDateFrom: creationDateFrom,
      creationDateTo: creationDateTo,
      issuerSubjectName: issuerSubjectName,
      status: status,
      isUser: "10000001" // TRUE
    });
    dispatch(setPageKey(serialNumber || subjectName || creationDateFrom || creationDateTo || issuerSubjectName || status))
  }, [searchFilterDebounced, creationDateTo]);

  return (
    <Box sx={{
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        flex: 1,
        mt: "-1rem",
        mb: "0.7rem",
      }}>
      <InputForm
        name="serialNumber"
        disabled={false}
        errors={errors}
        control={control}
        required
        placeholder="Certificate Serial Number"
        maxLength={100}
        isLoading={false}
        onChange={handleSerialNumber}
      />
      <InputForm
        name="subjectName"
        disabled={false}
        errors={errors}
        control={control}
        required
        placeholder="Subject Name"
        maxLength={100}
        isLoading={false}
        onChange={handleSubjectName}
      />
      <DatePickerForm
        name="creationDateFrom"
        defaultValue={null}
        control={control}
        errors={errors}
        placeholder="Creation Date Form"
        disabled={false}
        isLoading={false}
        required
        onChange={handleCreationDateFrom}
      />
      <DatePickerForm
        name="creationDateTo"
        defaultValue={null}
        control={control}
        errors={errors}
        placeholder="Creation Date To"
        disabled={false}
        isLoading={false}
        required
        minDate={(new Date(creationDateFrom).getTime())}
        onChange={handleCreationDateTo}
      />
      <InputForm
        name="issuerSubjectName"
        disabled={false}
        errors={errors}
        control={control}
        required
        placeholder="Issuer Subject Name"
        maxLength={100}
        isLoading={false}
        onChange={handleIssuerSubjectName}
      />
      <SelectFormm
        name="status"
        defaultValue={1}
        options={optStatusSelect}
        placeholder="Status"
        disabled={false}
        isLoading={false}
        control={control}
        errors={errors}
        required
        onChangeForm={handleStatus}
      />
    </Box>
  );
};

export default SearchUserCertificateSearch;
