import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../service/instance";

interface IFormGroup {
  isLoadingGroup: boolean;
  dataFGroup: any;
  editDataFGroup: any;
  formGroupSelect: any;
  totalRecFGroup: any;
  msgSccsFGr: string;
};

const initialState: IFormGroup = {
  isLoadingGroup: false,
  dataFGroup: [],
  editDataFGroup: {},
  formGroupSelect: [],
  totalRecFGroup: 0,
  msgSccsFGr: "",
};

export const getFormGroupId = createAsyncThunk<any, any, { rejectValue: any }>(
  "formGroup/list/id",
  async (id, { rejectWithValue }) => {
    try {
      const resp: any = await instance.get(`/form-group/${id}`);
      console.log(id, ' =============> form data')
      console.log(resp.data, " ========> data form group");
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const getFormGroup = createAsyncThunk<any, any, { rejectValue: any }>(
  "formGroup/list",
  async (data, { rejectWithValue }) => {
    try {
      const resp: any = await instance.get("/form-groups", {
        params: data,
      });
      // console.log(data, ' =============> form data')
      // console.log(resp.data, " ========> data form group");
      let formGroupData = resp.data.data
      let formGroupList = formGroupData.data.map((e: any) => ({
        label: e.uc_frm_grp_name,
        value: e.uc_frm_grp_id,
      }));
      let totalRecord = formGroupData.total_records;
      return {formGroupData, formGroupList, totalRecord};
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const createFormGroup = createAsyncThunk<any, any, { rejectValue: any }>(
  "formGroup/create",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const resp: any = await instance.post("/form-group", data);
      // console.log(data, " ===========> isi data");
      // console.log(resp.data, " ========> form response group");
      dispatch(getFormGroup({pagesize: 10}))
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const updateFormGroup = createAsyncThunk<any, any, { rejectValue: any }>(
  "formGroup/update",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await instance.put(
        `/form-group/${data.uc_frm_grp_id}`,
        data
      );
      // console.log(data, " ================> data from edit page");
      // console.log(resp.data, " ========> data form edit group");
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const deleteFormGroup = createAsyncThunk<any, any, { rejectValue: any }>(
  "formGroup/delete",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const resp = await instance.delete(`/form-group/${data}`);
      console.log("data form delete group ========> ", resp.data);
      dispatch(getFormGroup({pagesize: 10}));
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const formGroupSlice = createSlice({
  name: "formGroup",
  initialState,
  reducers: {
    rmvMsgSccsFGr: (state) => {
      state.msgSccsFGr = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getFormGroup.pending, (state) => {
        state.isLoadingGroup = false;
      })
      .addCase(getFormGroup.fulfilled, (state, action) => {
        const { formGroupData, formGroupList, totalRecord } = action.payload
        state.dataFGroup = formGroupData.data;
        console.log('data extrareduce get form group ===> ', totalRecord)
        // let fromGrList = action.payload.data;
        state.formGroupSelect = formGroupList
        // .map((e: any) => ({
        //   label: e.uc_frm_grp_name,
        //   value: e.uc_frm_grp_id,
        // }));
        state.totalRecFGroup = totalRecord
      })
      .addCase(getFormGroup.rejected, (state) => {
        state.isLoadingGroup = false;
      })
      .addCase(createFormGroup.pending, (state) => {
        state.isLoadingGroup = false;
      })
      .addCase(createFormGroup.fulfilled, (state, action) => {
        state.msgSccsFGr = action.payload?.msg;
      })
      .addCase(createFormGroup.rejected, (state)=> {
        state.isLoadingGroup = false
      })
      .addCase(updateFormGroup.pending, (state)=> {
        state.isLoadingGroup = false;
      })
      .addCase(updateFormGroup.fulfilled, (state, action)=> {
        state.msgSccsFGr = action.payload?.msg
      })
      .addCase(getFormGroupId.pending, (state)=> {
        state.isLoadingGroup = true
      })
      .addCase(getFormGroupId.fulfilled, (state, action)=> {
        state.editDataFGroup = action.payload.data
      })
  },
});

export const { rmvMsgSccsFGr } = formGroupSlice.actions;

export default formGroupSlice.reducer;
