import ApprovalIcon from '@mui/icons-material/Approval';
import AnimationIcon from '@mui/icons-material/Animation';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import BackpackIcon from '@mui/icons-material/Backpack';
import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import { ISidebar } from '../interfaces/ISidebar';

const dictionary: any = {
  0: ApprovalIcon,
  1: AnimationIcon,
  2: AssignmentIndIcon,
  3: BackpackIcon,
  4: Battery2BarIcon,
};
const sidebarParentChild = (data: any) => {
  const routes: Array<ISidebar> = [];
  data.forEach((e: any, i: number) => {
    
    const sortedData = [...e.form_types]
    sortedData.sort((a, b) => a.form_type_order - b.form_type_order)

    const childRoute: Array<ISidebar> = [];
    sortedData?.forEach((i: any, index: number) => {
      childRoute.push({
        key: i.form_type_uid,
        label: i.form_type_name,
        path: `${i.form_type_name.split(' ').join('-')}?form-type-uid=${
          i.form_type_uid
        }`,
        icon: undefined,
        child: [],
      });
    });

    const parentRoute = {
      key: e.form_group.form_group_id,
      label: e.form_group.form_type_name,
      path:
        e.form_types?.length === 0
          ? `${e.form_group.form_type_name
              .split(' ')
              .join('-')}?form-group-id=${e.form_group_id} `
          : e.form_group.form_type_name.split(' ').join('-'),
      icon: dictionary[i],
      child: childRoute,
    };

    routes.push(parentRoute);
  });
  return routes;
};

export default sidebarParentChild;
