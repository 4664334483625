
import IRoutes from "../interfaces/IRoutes";
import EmptyPage from "../pages/empty/EmptyPage";
import NotFound from "../pages/notFound/NotFound";
import SearchCertificate from "../pages/searchCertificate/SearchCertificate";
import SearchCertificateDetail from "../pages/searchCertificate/SearchCertificateDetail";

const SearchCertificateReq: Array<IRoutes> = [
  {
    element: <SearchCertificate />,
    path: "/search-certificate",
  },
  {
    element: <SearchCertificateDetail />,
    path: "/search-certificate/sign-csr/:id",
  }
];

const SearchCertificateReqErr: Array<IRoutes> = [
  {
    element: <NotFound />,
    path: "/search-certificate",
  },
  {
    element: <NotFound />,
    path: "/search-certificate/sign-csr/:id",
  }
];

const SearchCertificateReqEmpty: Array<IRoutes> = [
  {
    element: <EmptyPage />,
    path: "/search-certificate",
  },
  {
    element: <EmptyPage />,
    path: "/search-certificate/sign-csr/:id",
  }
];

export { SearchCertificateReq, SearchCertificateReqErr, SearchCertificateReqEmpty };