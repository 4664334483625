import { TypedUseSelectorHook,useDispatch, useSelector } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import userReducer from './feature/user'
import  adminSlice  from './feature/admin'
import certificateProfile from './feature/certificateProfileSlice'
import cerProf from './feature/cerProf'
import menu from './feature/menu'
import form from './feature/form'
import formGroup from './feature/formGroup'
import serviceDefinition from './feature/serviceDefinition'
import structureCaCrls from './feature/structureCaCrls'
import importCa from './feature/importCa'

export const store = configureStore ({
    reducer: {
        user: userReducer,
        admin: adminSlice,
        certy: certificateProfile,
        cer: cerProf,
        menu: menu,
        form: form,
        fGroup: formGroup,
        serDef: serviceDefinition,
        cerCA: structureCaCrls,
        impCa: importCa
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;