import {
  Box,
  Pagination,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../../store';
import InputForm from '../forms/inputForm';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import SelectForm from '../forms/selectFormm';
import { useSearchParams } from 'react-router-dom';
import { getSearchCertificate } from '../../../store/feature/structureCaCrls';

const PaginationControl = ({ params, setParams, total }: any) => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [pagesize, setPagesize] = useState(10);
  const { keyPage } = useAppSelector((store: RootState) => store.certy);
  
  const schema = yup.object({
    goTo: yup.number().required(),
  });
  const {
    control,
    formState: { errors },
  } = useForm<{
    goTo: number;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      goTo: 0,
    },
  });

  const handlePerPage = (e: any) => {
    setPagesize(e.value);
  };

  useEffect(() => {
    let tempPage = page;
    const totalPage = calculateTotalPage();
    if (totalPage < page) {
      tempPage = totalPage;
    }
    setParams({
      ...params,
      page: tempPage,
      pagesize,
    })
    dispatch(
      getSearchCertificate({
        ...params,
        page: tempPage,
        pagesize,
      })
    );
  }, [page, pagesize]);

  useEffect(()=> {
    setPage(1)
  }, [keyPage])

  const handlePaginate = (event: any, page: any) => {
    setPage(page);
  };

  const calculateTotalPage = () => {
    return Math.ceil(total / pagesize);
  };

  const handlePageGoto = (e: any) => {
    if (e === "" || e === undefined) {
      setPage(1);
      return;
    }
    setPage(e);
  };
  const optSelect = [
    {
      label: "10/page",
      value: 10,
    },
    {
      label: "20/page",
      value: 20,
    },
    {
      label: "30/page",
      value: 30,
    },
    {
      label: "50/page",
      value: 50,
    },
    {
      label: "100/page",
      value: 100,
    },
  ];

  return (
    <Box
      sx={{
        flexDirection: 'row',
        paddingY: '13px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingRight: '20px',
        gap: 4,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          justifyContent: 'center',
          width: '170px',
          // paddingTop: '10px'
        }}
      >
        <Typography
          variant="paragraph1"
          fontFamily={'Open Sans'}
          // paddingBottom={'15px'}
          mt="10px"
        >
          Total {total}
        </Typography>
        <SelectForm
          name="perPage"
          defaultValue={1}
          options={optSelect}
          placeholder="10/page"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          onChangeForm={handlePerPage}
        />
      </Box>

      <Pagination
        count={calculateTotalPage()}
        variant="outlined"
        shape="rounded"
        onChange={handlePaginate}
        sx={{mt: 2}}
        page={page}
      />
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          justifyContent: 'center',
          width: '100px',
        }}
      >
        <Typography variant="body1" mt="10px">
          Go to
        </Typography>
        <InputForm
          onChange={handlePageGoto}
          name="goTo"
          required
          maxLength={3}
          control={control}
          disabled={false}
          errors={errors}
          isLoading={false}
          sxFL={{ color: 'red', backgroundColor: 'red' }}
          endAdornment={<Typography variant="body16Regular"></Typography>}
          sxEndAdornment={{ backgroundColor: 'red' }}
        />
      </Box>
    </Box>
  );
};

export default PaginationControl;
