export const UserData = [
    {
        id: 1,
        year: 2016,
        userGain: 80000,
        userLost: 823
    },
    {
        id: 2,
        year: 2014,
        userGain: 94520,
        userLost: 453
    },
    {
        id: 3,
        year: 2019,
        userGain: 60370,
        userLost: 772
    },
    {
        id: 4,
        year: 2015,
        userGain: 54050,
        userLost: 863
    },
    {
        id: 5,
        year: 2013,
        userGain: 30540,
        userLost: 445
    },
    {
        id: 6,
        year: 2011,
        userGain: 20457,
        userLost: 654
    },
    {
        id: 7,
        year: 2012,
        userGain: 80675,
        userLost: 324
    },
    {
        id: 8,
        year: 2014,
        userGain: 70034,
        userLost: 983
    },
]