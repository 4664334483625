import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { RootState, useAppSelector } from './store';
import { SignceReq, SignceReqEmpty, SignceReqErr } from './routes/signCerReq';
import { StructureCaCrls, StructureCaCrlsEmpty, StructureCaCrlsErr } from './routes/structureCaCrls';
import { SearchCertificateReq, SearchCertificateReqEmpty, SearchCertificateReqErr } from './routes/searchCertificate';
import { SearchUserCertificateReq, SearchUserCertificateReqEmpty, SearchUserCertificateReqErr } from './routes/searchUserCertificate';
import routes from './routes';

function App() {
  const { accessCrud } = useAppSelector((store: RootState) => store.user);
  const accessCaStructure = accessCrud.find(
    (item: any) => item.uid === '/ca-structure-and-crls'
  );
  const accessSignCert = accessCrud.find(
    (item: any) => item.uid === '/sign-certificate-request'
  );
  const accessSearchCert = accessCrud.find(
    (item: any) => item.uid === '/search-certificate'
  );
  const accessUserSearchCert = accessCrud.find(
    (item: any) => item.uid === '/search-user-certificate'
  );

  const filteredRoutes = routes[0].children?.filter(
    (item: any) =>
      !StructureCaCrls.includes(item) &&
      !StructureCaCrlsErr.includes(item) &&
      !StructureCaCrlsEmpty.includes(item) &&
      !SignceReq.includes(item) &&
      !SignceReqErr.includes(item) &&
      !SignceReqEmpty.includes(item) &&
      !SearchCertificateReq.includes(item) &&
      !SearchCertificateReqErr.includes(item) &&
      !SearchCertificateReqEmpty.includes(item) &&
      !SearchUserCertificateReq.includes(item) &&
      !SearchUserCertificateReqErr.includes(item) &&
      !SearchUserCertificateReqEmpty.includes(item)
  );

  if (accessCaStructure != undefined) {
    filteredRoutes?.push(...StructureCaCrls);
  } else {
    if(accessCrud.length != 0) {
      filteredRoutes?.push(...StructureCaCrlsErr);
    } else {
      filteredRoutes?.push(...StructureCaCrlsEmpty);
    }
  }

  if (accessSignCert != undefined) {
    filteredRoutes?.push(...SignceReq);
  } else {
    if(accessCrud.length != 0) {
      filteredRoutes?.push(...SignceReqErr);
    } else {
      filteredRoutes?.push(...SignceReqEmpty);
    }
  }

  if (accessSearchCert != undefined) {
    filteredRoutes?.push(...SearchCertificateReq);
  } else {
    if(accessCrud.length != 0) {
      filteredRoutes?.push(...SearchCertificateReqErr);
    } else {
      filteredRoutes?.push(...SearchCertificateReqEmpty);
    }
  }

  if (accessUserSearchCert != undefined) {
    filteredRoutes?.push(...SearchUserCertificateReq);
  } else {
    if(accessCrud.length != 0) {
      filteredRoutes?.push(...SearchUserCertificateReqErr);
    } else {
      filteredRoutes?.push(...SearchUserCertificateReqEmpty);
    }
  }

  routes[0].children = filteredRoutes;

  const router = createBrowserRouter(routes);
  return <RouterProvider fallbackElement="..loading" router={router} />;
}

export default App;
