import {
  Box,
  Button,
  Chip,
  Grid,
  Stack,
  Typography,
  colors,
} from "@mui/material";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
// import {
//   deleteFormGroup,
//   getFormGroup,
// } from '../../../store/feature/formGroup';
import { useNavigate, useSearchParams } from "react-router-dom";
import PaginationControl from "../pagination/Pagination";
import { useEffect } from "react";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import "./FormGroupDataGrid.css";
import { rows } from "../../../utils/datajson/rowData/rowStrucCaCrls";
import { getCertCaCrlsDetails } from "../../../store/feature/structureCaCrls";

const StrucCaCrlsTableDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { caCrlsDetails, revokeMsg } = useAppSelector((state: RootState) => state.cerCA);
  const [searchParams] = useSearchParams();
  //   useEffect(() => {
  //     dispatch(getFormGroup({ pagesize: 10 }));
  //   }, []);
  const certSerial = searchParams.get("cert");
  const caCrlsDetailsNoCertificate = caCrlsDetails.filter(
    (item: any) =>
      item.label != "Certificate Chain Serial Number" &&
      item.label != "Certificate Revoke Date" &&
      item.label != "Certificate Revoke Reason"
  );
  const certSerialNumber = caCrlsDetails.filter(
    (item: any) => item.label === "Certificate Serial Number"
  )

  useEffect(() => {
    dispatch(getCertCaCrlsDetails(certSerial));
  }, []);
  useEffect(() => {
    if(revokeMsg?.length != 0){
    Swal.fire({
      icon: 'success',
      title: revokeMsg,
      showConfirmButton: false,
      timer: 1500
    })
    console.log('revokeReason ===> ', revokeMsg?.length)
    dispatch(getCertCaCrlsDetails(certSerialNumber[0]?.value));
  }
  }, [revokeMsg]);

  const columns: GridColDef[] = [
    {
      field: "label",
      headerName: "Label",
      flex: 1,
      minWidth: 500,
    },
    {
      field: "value",
      headerName: "Value",
      flex: 3,
      minWidth: 500,
      cellClassName: "super-app-theme--header",
      renderCell: (params) => {
        const RenderLongParagraph = () => {
          return (
            <Box
              display="flex"
              alignItems="center"
              minHeight="200px"
              width="100%"
              p={2}
              sx={{ wordBreak: "break-word" }}
            >
              <Typography variant="body1">{params.value}</Typography>
            </Box>
          );
        };

        const RenderShortParagraph = () => {
          return (
            <Box display="flex" alignItems="center" p={2}>
              <Typography variant="body1">{params.value}</Typography>
            </Box>
          );
        };
        let renderFunction =
          params.value?.length >= 100 ? (
            <RenderLongParagraph />
          ) : (
            <RenderShortParagraph />
          );
        return renderFunction;
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          height: "600px",
          width: "90%",
          "& .super-app-theme--header": {
            borderLeft: "1px solid #ddd;",
          }
        }}
      >
        <DataGrid
          rows={caCrlsDetailsNoCertificate}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          autoHeight
          getRowHeight={() => "auto"}
          headerHeight={0}
        />
      </Box>
    </>
  );
};

export default StrucCaCrlsTableDetails;
